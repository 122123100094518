.fotorama__nav-wrap--vertical {
    &.fotorama__nav-wrap {
        position: absolute;
        display: inline-block;
        top: 0;
        right: 0;
    }
}

.fotorama__stage {
    left: 0 !important;
}