//
//  Product view
//  ---------------------------------------------

.product-header.wrapper {
    .category-title h2 {
        margin: 10px;
        font-size: 16px;
        font-weight: 700;
        color: #FFF;
    }

    .blank.right {
        width: 300px;
    }
}

.page-title-wrapper.product {
    color:#000;
    width: 100%;
    h1.page-title {
        font-size: 30px;
        font-weight: 700;
        margin-top: 0;
        padding-bottom: 20px;
        border-bottom: $color-green2 dashed;
        margin-bottom: 25px;
        text-transform: uppercase;
        line-height: 32px;
    }
}

.tienda-metros {
    margin-top: 120px;
    
    &>.message {
        font-size: 18px;
        font-weight: 900;
        color: $color-red13;
    }
}

.tienda-metros-sku {
    margin-top: 50px;
}

.product.attribute.sku {
    position: relative;
    float: left;
    margin-bottom: 20px;
    font-family: $secondary__font;
    color: $color-black;
    font-size: 14px;
    font-weight: 600;

    strong {
        font-weight: 800;
    }
}

.product.attribute.overview {
    position: relative;
    float: left;
    font-family: $main__font;
    color: $color-black;
    font-size: 14px;
    text-align: justify;
}

.product-info-main,
.product.media {
    margin-top: 20px;
}

.product.media {
    .product.photo .photo.image {
        @extend .abs-adaptive-images-centered;
    }

    .placeholder .photo.container {
        max-width: 100%;
    }

    .notice {
        @include lib-css(color, $text__color__muted);
        @include lib-font-size($font-size__s);
        margin: $indent__s 0;
    }

    .product.thumbs {
        margin: $indent__base 0 $indent__l;
    }

    .items.thumbs {
        @include lib-list-inline();

        .active {
            display: block;
            line-height: 1;
        }
    }
}

.block.related {
    float: left;
    width: 100%;

    .product-image-container {
        width: 140px !important;
    }

    .product-item {
        margin: 0 !important;
        padding: 0 !important;
    }
}

.product.info.detailed {
    position: relative;
    float: left;
    width: 100%;
    clear: both;
    margin: 30px 0px;
    font-family: $main__font;

    .additional-attributes {
        width: auto;
        @include lib-table-resize(
        $_th-padding-left   : 0,
            $_th-padding-right  : $indent__l,
            $_th-padding-bottom : $indent__s,
            $_td-padding-bottom : $indent__s
            );
    }
}

.product-info-main {

    margin-bottom: 60px;

    .page-title-wrapper {
        .page-title {
            line-height: $line-height__base;
            margin-bottom: $indent__s;
        }
    }

    .stock {
        &.available,
        &.unavailable {
            display: inline-block;
            font-weight: $font-weight__bold;
            margin-right: $indent__base;
            text-transform: uppercase;
            vertical-align: top;
        }
    }

    .product {
        &.attribute {
            &.sku {
                display: inline-block;
                vertical-align: top;

                > .value {
                    display: inline-block;
                    vertical-align: top;
                    word-break: break-all;
                }

                .type {
                    margin-right: $indent__xs;
                }
            }

            &.metros {
                font-size: 10px;

                strong {
                    font-weight: 400;
                }

                div {
                    vertical-align: top;
                    float:left;
                }
            }

            &.description {
                margin-top: 10px;
            }

            &.overview {
                position: relative;
                float: left;
                width: 100%;
                margin: 0px 0px 40px 0px;
            }
        }

        &.alert {
            margin: $indent__s 0;
        }
    }

    &.metros {
        display: inline-block;
        vertical-align: top;

        strong {
            font-weight: 400;
        }
    }

    .product-reviews-summary .reviews-actions {
        @include lib-font-size($font-size__base);
    }
}

.product-options-wrapper {
    display: inline-block;
    float: right;
    width: 210px;
    .fieldset-product-options-inner {
        .legend {
            @include lib-css(font-weight, $font-weight__bold);
            @include lib-css(margin, 0 0 $indent__xs);
            @include lib-font-size(14px);
            border: none;
            display: inline-block;
            float: none;
            padding: 0;
        }

        //  Date & Time custom option (Affect Time that goes only after Date)
        input.datetime-picker {
            ~ select.datetime-picker {
                margin-top: $indent__s;
            }
        }

        &.required,
        &._required {
            .legend {
                &:after {
                    content: '*';
                    @include lib-typography(
                    $_font-size   : $form-field-label-asterisk__font-size,
                        $_color       : $form-field-label-asterisk__color,
                        $_font-family : $form-field-label-asterisk__font-family,
                        $_font-weight : $form-field-label-asterisk__font-weight,
                        $_line-height : $form-field-label-asterisk__line-height,
                        $_font-style  : $form-field-label-asterisk__font-style
                        );
                    @include lib-css(margin, $form-field-label-asterisk__margin);
                }
            }
        }
    }

    .field {
        .note {
            display: block;
        }

        .price-notice {
            @extend .abs-adjustment-incl-excl-tax;
        }
    }
}

.product-options-bottom {
    margin-top: 30px;
}

.product-info-main,
.product-options-bottom {
    .product-description.title {
        margin-top: 40px;
        margin-bottom: 10px;
        font-weight: 700;
    }

    .price-box {
        display: inline-block;
        text-align: right;

        .price-including-tax + .price-excluding-tax,
        .weee + .price-excluding-tax,
        .weee {
            @include lib-font-size(12);
            line-height: 14px;
            margin-bottom: $indent__xs;

            .price {
                @include lib-font-size(12);
                font-weight: $font-weight__bold;
            }
        }

        .price-wrapper .price {
            font-size: 22px;
            font-weight: $font-weight__bold;
        }

        .price {
            white-space: nowrap;
        }
    }

    .special-price {
        display: block;
        text-align: left;
        margin: 0px 0px $indent__s 0px;

        .price-container {
            @include lib-font-size(14);
        }

        .price-label + .price-wrapper {
            display: inline-block;
        }
        .price-final_price {
            .price {
                color: #FF2800;
            }
        }
    }

    .price-final_price {
        .price {
            font-family: $secondary__font;
            color: $color-black;
            font-size: 18px;
            font-weight: 800;
        }

        .old-price {
            text-decoration: line-through;

            .price {
                font-size: 14px;
            }
        }
    }

    .precios {
        float: left;
        width: 75%;
    }

    .box-tocart {
        margin: $indent__base 0;

        .wrapper {
            position: relative;
            float: right;
            width: 25%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            
            &>.tienda-metros-message {
                font-weight: bold;
                text-align: left;
            }
        }

        .field.qty {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: auto;
            padding-right: 0.75 * $indent__base;
            float: left;

            .label {
                position: relative;
                float: left;
                width: auto;
                span {
                    font-family: $secondary__font;
                    color: $color-black;
                    font-weight: 600;
                }
            }

            .control {
                position: relative;
                width: auto;
                margin-left: 10px;
                margin-right: 10px;
                display:inline-block;

                .qty-control {
                    height: 25px;
                    width: 25px;
                    background: $color-green2;
                    display: inline-block;
                    vertical-align: top;
                    text-align: center;
                    padding-top: 5px;
                    color:#FFF;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }

            .metros_x_rollo{
                margin-left: 0px;
            }

        }

        .price-block {
            margin-top: 15px;
            float:right;

            .mult {
                display: none;
                font-size: 10px;

                div {
                    display:inline-block
                }
            }

            .price-label {

            }
        }

        .total-price {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .label-price {
                margin-right: 15px;
                font-family: $secondary__font;
                color: $color-black;
                font-size: 18px;
                font-weight: 800;
            }
        }

        .input-text.qty {
            margin-left: -4px;
            margin-right: -3px;
            $tocart-input-size: $button__line-height__l + 30px;
            height: 30px; /*$tocart-input-size;*/
            text-align: center;
            width: 30px; /*$tocart-input-size;*/
            border: 1px solid $color-black;
            background-color: transparent;
            font-family: $secondary__font;
            font-size: 14px;
            width: 38px;
            text-align: center !important;
        }

        .actions {
            text-align: center;
        }

        .action.tocart {
            @extend .abs-button-l;
        }
    }

    .product-addto-links {
        margin: $indent__base 0;
    }

    .action.tocompare {
        @extend .abs-action-addto-product;
        vertical-align: top;
    }
}

.product-info-main .box-tocart .actions {
    position: relative;
    float: left;
    width: 100%;
    display: block;
    clear: both;

    #product-addtocart-button {
        position: relative;
        float: left;
        left: 50%;
        transform: translateX(-50%);
        font-family: $secondary__font;
        font-weight: 700;
        text-transform: uppercase;
        width: 90%;
        background: $color-red13;
        border: 0px;
        border-radius: 0px;

        &:hover {
            opacity: 0.7;
        }
    }
}

.product.info.detailed {
    .additional-attributes > tbody > tr > th > h3 {
        margin: 0px;
        font-size: 14px;
        font-weight: 700;
    }
}

.prices-tier {
    @extend .abs-reset-list;
    @include lib-css(background, $sidebar__background-color);
    margin: $indent__s 0;
    padding: $indent__s (0.75 * $indent__base);

    .price-container {
        display: inline-block;
    }

    .price-including-tax,
    .price-excluding-tax,
    .weee {
        display: inline-block;

        .price {
            @include lib-font-size(14);
            font-weight: $font-weight__bold;
        }
    }
}

.ui-dialog-titlebar-close {
    @include lib-button-as-link();
}

.product-social-links {
    float: right;
    width: 42px;

    .magebuzz-social,
    .product-addto-links {
        position: relative;
        width: auto;
    }

    .magebuzz-social {
        float: left;

        .social-button {
            position: relative;
            float: left;
            width: 40px;
            height: 40px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 70%;
            background-color: $color-white;
            margin: 16px 5px 0px 0px;
            overflow: hidden;

            &:hover {
                opacity: 0.7;
            }

            iframe,
            div,
            span,
            a {
                position: absolute;
                opacity: 0;
                transform: scale(4);
            }

            &.facebook_button {
                background-image: url('../images/share_facebook.jpg');
            }

            &.twitter_button {
                background-image: url('../images/share_twitter.jpg');
            }

            &.google_button {
                background-image: url('../images/share_google.jpg');
            }

            &.pinit_button {
                background-image: url('../images/share_pinit.jpg');
            }

            &.whatsapp_button {
                background-image: url('../images/share_whatsapp.jpg');
            }
        }
    }

    .product-addto-links {
        float: right;

        .action {
            position: relative;
            float: left;
            width: 40px;
            height: 40px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            margin: 16px 0px 0px 5px;
            overflow: hidden;

            &:hover {
                opacity: 0.7;
            }

            span {
                display: none;
            }

            &.tozoom {
                background-image: url('../images/button_zoom.jpg');
            }

            &.towishlist {
                background-image: url('../images/button_wishlist.jpg');
            }
        }
    }
}

.block.related {
    .action.select {
        margin: 0 $indent__xs;
    }
}

.gallery-placeholder {
    width: calc(100% - 45px);
    float: left;
    background: #FFF;
}

.table-precios{
    width: 90%;
    font-size: 12px;
    text-align: center;
    tr{
        td{
            border: 1px #000 solid;
            &.titulo{
                font-weight: bold;
                text-align: center;
            }
            &.precio {
                text-align: center;
            }
            &.price-discount {
                text-align:center;
            }
        }
    }
}

.stock-label {
    float: left;
    width: 100%;
    margin-bottom: 10px;
}

.actions .stock-label {
    display: none;
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product-info-main,
    .product-options-bottom {
        .box-tocart {

            .actions {
                padding-top: $indent__m;
                text-align: center;
                vertical-align: bottom;
            }
        }
    }

    .product-info-main {
        .page-title-wrapper {
            .page-title {
                margin-top: -13px;
            }
        }
    }
}


//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .product-info-main .box-tocart .actions #product-addtocart-button {
        margin-top: 30px;
    }
    .table-precios{
        width: 100%;
        font-size: 12px;
    }
    .product-header.wrapper {
        //display: block;
    }
    .product-info-main .box-tocart .fieldset .wrapper{
        display: contents;
    }
}
