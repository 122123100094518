%full-width {
  position: relative;
  float: left;
  width: 100%;
  max-width: none;
}

.amasty_custom_form {
  @extend %full-width;
}

.cms-team {
  .page-wrapper {
    background-color: $color-white;
  }

  h3 {
    @extend %full-width;
    font-family: $secondary__font;
    color: $color-black;
    font-size: 16px;
    font-weight: 700;
    margin: 0px;
    padding: 50px 0px 40px 0px;
  }

  .amasty_custom_form {
    @extend %full-width;
    margin-bottom: 50px;

    .fieldset {
      @extend %full-width;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0px;

      .field {
        position: relative;
        float: left;
        width: 24%;
        margin-right: 1%;
        margin-bottom: 60px;

        label {
          display:none;
          width: 30%;
          padding-top: 12px;
        }

        .control {
          width: 90%;

          input,
          select {
            height: 50px;
            padding: 15px;
          }
        }
      }
    }
    .actions-toolbar {
      margin: 0px 0px 50px 0px !important;

      .more-info {
        position:absolute;
        left: 0;
        top: 15px;

        .privacy-politic {
          margin-left: 30px;
        }
      }

      .action.submit.primary {
        padding: 20px 50px;
        font-size: 14px;
      }
    }
  }
}