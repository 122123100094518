//
//  Variables
//  _____________________________________________

$checkout-progress-bar__font-size                              : 18px;
$checkout-progress-bar__font-weight                            : $font-weight__light;
$checkout-progress-bar__margin                                 : $indent__base;

$checkout-progress-bar-item__background-color                  : $color-gray-middle1;
$checkout-progress-bar-item__border-radius                     : 6px;
$checkout-progress-bar-item__color                             : $primary__color;
$checkout-progress-bar-item__margin                            : $indent__s;
$checkout-progress-bar-item__width                             : 185px;
$checkout-progress-bar-item__active__background-color          : $color-orange-red1;
$checkout-progress-bar-item__complete__color                   : $link__color;

$checkout-progress-bar-item-element__width                     : 38px;
$checkout-progress-bar-item-element__height                    : $checkout-progress-bar-item-element__width;

$checkout-progress-bar-item-element-inner__background-color    : $page__background-color;
$checkout-progress-bar-item-element-inner__color               : $checkout-progress-bar-item__color;
$checkout-progress-bar-item-element-outer-radius__width        : 6px;
$checkout-progress-bar-item-element-inner__width               : $checkout-progress-bar-item-element__width - ($checkout-progress-bar-item-element-outer-radius__width * 2);
$checkout-progress-bar-item-element-inner__height              : $checkout-progress-bar-item-element-inner__width;
$checkout-progress-bar-item-element-inner__active__content     : $icon-checkmark;
$checkout-progress-bar-item-element-inner__active__font-size   : 28px;
$checkout-progress-bar-item-element-inner__active__line-height : 1;

//
//  Checkout Progress Bar
//  ---------------------------------------------



.opc-progress-bar {
    position: relative;
    float: left;
    width: 100%;
    @extend .abs-reset-list;
    margin-bottom: 40px;
    counter-reset: i;
    font-size: 0;
}

.opc-progress-bar-item {
    @include lib-css(margin, 0 0 $checkout-progress-bar-item__margin);
    display: inline-block;
    position: relative;
    float: left;
    text-align: center;
    vertical-align: top;
    width: 50%;

    &:before { // Horizontal line
      content: "";
      position: relative;
      float: left;
      width: 100%;
      height: 36px;
      background-image: url('../images/checkout_step.png');
      background-position: left top;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &._active {
        &:before {
          background-image: url('../images/checkout_step_active.png');
        }
    }

    > span {
        display: inline-block;
        width: 100%;
        word-wrap: break-word;
        font-family: $secondary__font;
        color: $color-black;
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;

        &:before {
            content: counter(i) ".";
            counter-increment: i;
            margin-right: 5px;
        }
    }

    &._active {
        > span {
            @include lib-css(color, $checkout-progress-bar-item__color);
        }
    }

    &._complete {
        cursor: pointer;

        > span {
            @include lib-css(color, $checkout-progress-bar-item__color);
        }
    }
}