//
//  Variables
//  _____________________________________________

$checkout-payment-option-title__border            : $checkout-payment-method-title__border;
$checkout-payment-option-title__color             : $link__color;
$checkout-payment-option-title__padding           : $checkout-payment-method-title__padding;
$checkout-payment-option-title-mobile__padding    : $checkout-payment-method-title-mobile__padding;

$checkout-payment-option-title-icon__font-size    : 32px;
$checkout-payment-option-title-icon__line-height  : 16px;
$checkout-payment-option-title-icon__margin       : 0;
$checkout-payment-option-title-icon__color        : $minicart-icons-color;
$checkout-payment-option-title-icon__hover__color : $primary__color;

$checkout-payment-option-content__padding__xl     : $checkout-payment-method-content__padding__xl;

.checkout-payment-method {
    #checkout-payment-method-load {
        width: 100%;
    }
    .payment-methods {
      position: relative;
      float: left;
      width: 100%;

      .payment-group {
        position: relative;
        float: left;
        width: 100%;

        .payment-method {
          position: relative;
          float: left;
          width: 100%;
          font-family: $secondary__font;
          font-weight: 700;
          border-bottom: 2px dashed $color-green3;
          .total_buy {
              display: none;
          }
        }
      }
    }

    .actions-toolbar .primary {
        margin-bottom: 30px;
    }

    .form-discount {
      button {
        position: relative;
        float: left;
        left: 50%;
        transform: translateX(-50%);
        font-family: $secondary__font;
        font-weight: 700;
        text-transform: uppercase;
        background: $color-red13;
        color: $color-white;
        border: 0px;
        border-radius: 0px;
        margin: 20px 0px 0px 0px;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .payment-option {
        width: 100%;
        &._active {
            .payment-option-title {
                .action-toggle {
                    &:after {
                        content: $icon-up;
                    }
                }
            }
        }

        &._collapsible {
            .payment-option-title {
                cursor: pointer;
                padding: 0px;
                position: relative;
                float: left;
                margin: 40px 0px;
                font-family: $secondary__font;
                color: $color-red13;
                font-size: 16px;
                font-weight: 700;

                span {
                  color: $color-red13 !important;
                }
            }

            .payment-option-content {
                display: none;
            }
        }
    }

    .payment-option-title {
        @include lib-css(border-top, $checkout-payment-option-title__border);
        @include lib-css(padding, $checkout-payment-option-title__padding 0);

        .action-toggle {
            @include lib-css(color, $checkout-payment-option-title__color);
            @include lib-icon-font(
                $icon-down,
                $_icon-font-size         : $checkout-payment-option-title-icon__font-size,
                $_icon-font-line-height  : $checkout-payment-option-title-icon__line-height,
                $_icon-font-color        : $checkout-payment-option-title-icon__color,
                $_icon-font-color-hover  : $checkout-payment-option-title-icon__hover__color,
                $_icon-font-color-active : $checkout-payment-option-title-icon__color,
                $_icon-font-margin       : $checkout-payment-option-title-icon__margin,
                $_icon-font-position     : after
            );
        }
    }

    .payment-option-content {
        @include lib-css(padding, 0 0 $indent__base $checkout-payment-option-content__padding__xl);
    }

    .payment-option-inner {
        margin: 0 0 $indent__base;
    }

    .credit-card-types {
        padding: 0;

        .item {
            display: inline-block;
            list-style: none;
            margin: 0 $indent__xs 0 0;
            vertical-align: top;

            &._active {
                font-weight: $font-weight__bold;

                img {
                    filter: grayscale(0%);
                }
            }

            &._inactive {
                opacity: 0.4;
            }

            span {
                display: inline-block;
                padding-top: 6px;
                vertical-align: top;
            }
        }

        img {
            filter: grayscale(100%); // For Webkit browsers
            transition: all 0.6s ease; // Fade to color for Chrome and Safari
        }
    }

    .ccard {
        .legend {
            @extend .abs-visually-hidden;
        }

        .fields {
            > .year {
                width: unset;
                padding-left: $indent__xs;
            }

            .select {
                padding-left: $indent__xs;
                padding-right: $indent__xs;
            }
        }

        .month {
            width: unset;
            .select {
                width: 140px;
            }
        }

        .year {
            .select {
                width: 80px;
            }
        }

        .number {
            .input-text {
                width: 225px;
            }
        }

        > .field.cvv {
            > .control {
                padding-right: $indent__base;
                width: auto;
            }
        }

        .cvv {
            .input-text {
                width: 55px;
            }
        }

        &.fieldset {
            > .field {
                .fields.group.group-2 {
                    .field {
                        width: auto !important;
                    }
                }
            }
        }
    }
}

.checkout-agreements {
    clear:both;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .checkout-payment-method {
        .payment-option {
            @include lib-css(margin, 0 -($checkout-payment-option-title-mobile__padding));

            .payment-option-title {
                @include lib-css(padding, $checkout-payment-option-title-mobile__padding)
            }

            .payment-option-content {
                @include lib-css(padding, 0 $checkout-payment-option-title-mobile__padding $indent__base);
            }
        }
    }
    .checkout-agreements {
        .checkout-agreement {
            display: flex;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .checkout-payment-method {
        .payment-option-title {
            @include lib-css(padding-left, $checkout-payment-option-content__padding__xl);
        }
        .payment-option-content {
            .payment-option-inner {
                + .actions-toolbar {
                    margin-left: 0;
                }
            }
        }
    }
}
