%full-width {
  position: relative;
  float: left;
  width: 100%;
  max-width: none;
}

.contact-index-index {
  .page-wrapper {
    background-color: $color-white;
  }

  .form {
    @extend %full-width;

    .fieldset {
      @extend %full-width;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0px;

      .legend {
        display: none;
      }

      .field {
        position: relative;
        float: left;
        width: 24%;
        margin: 0px 1% 20px 0px;

        &:before,
        &:after {
          display: none;
        }

        label {
          width: 100%;
          padding: 20px 0px 0px 0px;
        }

        .control {
          width: 100%;

          input,
          select {
            height: 50px;
            padding: 15px;
          }
        }

        &.note {
          width: 100%;
          margin: 0px;
          padding: 50px 0px 40px 0px;
          font-family: $secondary__font;
          font-size: 16px;
          font-weight: 700;
          text-align: left;
        }

        &.comment,
        &.captcha {
          width: 100%;
        }

        &.comment {
          textarea {
            height: 150px;
          }
        }
      }
    }

    .actions-toolbar {
      margin: 0px 0px 50px 0px !important;

      .more-info {
        position:absolute;
        left: 0;
        top: 15px;

        .privacy-politic {
          margin-left: 30px;
        }
      }

      .action.submit.primary {
        padding: 20px 50px;
        font-size: 14px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
    .field {
        display: contents;
    }
    .privacy-politic{
        display: block;
        margin-left: 0px;
    }
}
