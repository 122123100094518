//
//  Variables
//  _____________________________________________

$checkout-payment-method-title__border         : $checkout-shipping-method__border;
$checkout-payment-method-title__padding        : $checkout-shipping-method__padding;
$checkout-payment-method-title-mobile__padding : 15px;

$checkout-payment-method-content__padding__xl  : 22px;

$checkout-billing-address-details__line-height : 27px;
$checkout-billing-address-details__padding     : 0 0 0 23px;
$checkout-billing-address-form__max-width      : $checkout-shipping-address__max-width;

//
//  Common
//  _____________________________________________

.checkout-payment-method {
    .step-title {
        font-family: $secondary__font;
        color: $color-red13;
        font-size: 17px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        padding-bottom: 15px;
        border-bottom: 2px dashed $color-red13;
        margin-bottom: 15px;
        margin-top: -30px;
    }

    .payment-method {
        &:first-child {
            .payment-method-title {
                border-top: 0;
            }
        }

        &._active {
            .payment-method-content {
                display: block;
            }
        }
    }

    .payment-method-content {
        > .messages {
            position: fixed;
            top: 0px;
            z-index: 99;

            .message.error {
                padding: 10px 40px;
                height: 20px;
                min-width: 300px;

                > :first-child::after {
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    font-size: 28px;
                    line-height: 28px;
                    color: #b30000;
                    content: "\e616";
                    font-family: "icons-blank-theme";
                    margin: -14px 0 0;
                    vertical-align: middle;
                    display: inline-block;
                    font-weight: normal;
                    overflow: hidden;
                    speak: none;
                    text-align: center;
                    left: 0;
                    top: 18px;
                    width: 40px;
                    position: absolute;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }

        > .actions-toolbar {
            > .primary {
                .action {
                    &.primary {
                        @extend .abs-button-l;
                    }
                }
            }
        }
    }

    .payment-method-title {
        @include lib-css(padding, $checkout-payment-method-title__padding 0);
        margin: 0;

        .payment-icon {
            display: inline-block;
            margin-right: $indent__xs;
            vertical-align: middle;
        }

        .action-help {
            display: inline-block;
            margin-left: $indent__xs;
        }
    }

    .payment-method-content {
        display: none;
        @include lib-css(padding, 0 0 $indent__base $checkout-payment-method-content__padding__xl);
        .fieldset {
            &:not(:last-child) {
                margin: 0 0 $indent__base;
            }
        }
    }

    .payment-group {
        & + .payment-group {
            .step-title {
                margin: $indent__base 0 0;
            }
        }
    }

    .field-select-billing,
    .billing-address-form {
        @include lib-css(max-width, $checkout-billing-address-form__max-width);
    }

    .billing-address-same-as-shipping-block {
        margin: 0 0 $indent__s;
    }

    .checkout-billing-address {
        margin: 0 0 $indent__base;

        .primary {
            .action-update {
                margin-right: 0;
            }
        }

        .action-cancel {
            @extend .abs-action-button-as-link;
        }

        .billing-address-details {
            @include lib-css(line-height, $checkout-billing-address-details__line-height);
            @include lib-css(padding, $checkout-billing-address-details__padding);
        }
    }

    .payment-method-note {
        & + .checkout-billing-address {
            margin-top: $indent__base;
        }
    }

    .field-select-billing {
        > .label {
            @extend .abs-visually-hidden;
        }
    }

    .payment-method-iframe {
        background-color: transparent;
        display: none;
        width: 100%;
    }

    .no-payments-block {
        margin: $indent__base 0;
    }

    .payments {
        .legend {
            @extend .abs-visually-hidden;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .checkout-payment-method {
        .step-title {
            margin-top: 0px;
        }

        .payment-methods {
            @include lib-css(margin, 0 -($checkout-payment-method-title-mobile__padding));
            .payment-group {
                margin-left: 10px;
            }
        }

        .payment-method-title {
            @include lib-css(padding, $checkout-payment-method-title-mobile__padding)
        }

        .payment-method-content {
            @include lib-css(padding, 0 $checkout-payment-method-title-mobile__padding $indent__base);
        }

        .checkout-billing-address {
            .action-cancel {
                margin-top: $indent__s;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .checkout-payment-method {
        .actions-toolbar {
            .primary {
                float: right;
                margin: 0;
            }
        }

        .fieldset {
            > .field-select-billing {
                > .control {
                    float: none;
                    width: 100%;
                }
            }
        }

        .payment-method-content {
            .fieldset {
                > .field {
                    margin: 0 0 $indent__base;

                    &.type {
                        .control {
                            margin-left: 25.8%;
                        }

                        &.no-detection {
                            .control {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .checkout-billing-address {
        .action-update {
            float: right;
        }

        .actions-toolbar {
            .action-cancel {
                margin: 6px $indent__base 0 0;
            }
        }
    }
}
