//
//  Variables
//  _____________________________________________

$autocomplete__background-color  : $color-white;
$autocomplete__border            : 1px solid $form-element-input__border-color;
$autocomplete-item__border       : 1px solid $color-gray90;
$autocomplete-item__hover__color : $color-gray91;
$autocomplete-item-amount__color : $color-gray60;

.block-search {
    margin-bottom: 0;

    .block {
        &-title {
            display: none;
        }
    }

    .block-content {
        margin-bottom: 0;
    }

    .label {
        @include lib-icon-font(
            $_icon-font-content      : $icon-search,
            $_icon-font-size         : 35px,
            $_icon-font-line-height  : 33px,
            $_icon-font-color        : $minicart-icons-color,
            $_icon-font-color-hover  : $minicart-icons-color-hover,
            $_icon-font-color-active : $minicart-icons-color-hover,
            $_icon-font-text-hide    : true
        );
        display: inline-block;
        float: right;

        &.active {
            + .control {
                input {
                    position: static;
                }
            }
        }
    }

    .action.search {
        display: none;
    }

    .control {
        border-top: 1px solid $border-color__base;
        clear: both;
        margin: 0 (-$layout__width-xs-indent) -1px;
        padding: 0 $layout__width-xs-indent;
    }

    input {
        left: -300%;
        margin: 15px 0;
        position: absolute;
        border: 1px solid $color-black;
        border-right: 0px;
        color: $color-black;
        font-family: $secondary__font;
        font-size: 13px;
        text-transform: uppercase;
        background-color: $color-gray96;
    }

    input::-webkit-input-placeholder,
    ::-moz-placeholder,
    :-ms-input-placeholder,
    ::-webkit-input-placeholder,
    ::placeholder {
      color: $color-black;
      font-family: $secondary__font;
      font-size: 13px;
      text-transform: uppercase;
    }

    .nested {
        display: none;
    }

    .more-information {
        position: absolute;
        width: max-content;
        top: 60px;
        left: 50%;
        transform: translateX(-50%);
        font-family: $secondary__font;
        font-size: 16px;
        font-weight: 700;
        color: $color-black;
        .phone {
          font-weight: normal;
          color: $color-red13;

          &:before {
            content: "";
            display: inline-block;
            width: 15px;
            height: 15px;
            margin: 0px 4px;
            background-image: url('../images/sprite_phone1.png');
          }
        }
    }
    .action.search {
        position: relative;
        float: left;
        display: block;
        width: 32px;
        height: 32px;
        @include lib-button-reset();
        border-radius: 0px;
        padding: $indent__xs 0;
        z-index: 1;
        background-color: $color-red13;
        box-sizing: border-box;

        i {
          position: relative;
          float: left;
          width: 18px;
          height: 18px;
          left: 50%;
          margin: 0px 0px 0px -9px;
          background-image: url('../images/sprite_search.png');
          background-repeat: no-repeat;
        }

        &:hover,
        &:focus {
          background-color: $color-red13;
          opacity: 0.7;
        }
    }
}

.search-autocomplete {
    @extend .abs-add-box-sizing;
    display: none;
    margin-top: -15px;
    overflow: hidden;
    position: absolute;
    z-index: 3;

    ul {
        @include lib-list-reset-styles();

        li {
            @include lib-css(border-top, $autocomplete-item__border);
            cursor: pointer;
            margin: 0;
            padding: $indent__xs $indent__xl $indent__xs $indent__s;
            position: relative;
            text-align: left;
            white-space: normal;

            &:not(:empty) {
                border-top: 0;
                @include lib-css(border, $autocomplete__border);
                @include lib-css(background, $autocomplete__background-color);
            }

            &:first-child {
                border-top: none;
            }

            &:hover,
            &.selected {
                @include lib-css(background, $autocomplete-item__hover__color);
            }

            .amount {
                @include lib-css(color, $autocomplete-item-amount__color);
                position: absolute;
                right: 7px;
                top: $indent__xs;
            }
        }
    }
}

.form.search.advanced {
    .fields.range {
        .field {
            &:first-child {
                position: relative;

                .control {
                    padding-right: 25px;

                    &:after {
                        content: ' \2013 ';
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        text-align: center;
                        top: 6px;
                        width: 25px;
                    }
                }
            }

            &:last-child {
                position: relative;

                div.mage-error[generated] {
                    left: 0;
                    position: absolute;
                    top: 32px;
                }
            }

            &.with-addon {
                .control {
                    padding-right: 45px;
                }
            }
        }
    }

    .group.price {
        .addon {
            .addafter {
                background: none;
                border: 0;
                padding-top: 6px;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}

.search.summary {
    margin-bottom: $indent__s;
}

.catalogsearch-result-index {
    .category-header {
        display: none;
    }

    .category.wrapper {
        .sidebar.sidebar-additional {
            display:none;
        }
        .products-grid {
            width: 100%;
            padding-left: 0px;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .block-search {
        .block-content {
            margin-bottom: 0;
            .field.search {
                width: calc(90% - 32px);
                margin-left: 5%;

                .label {
                    display: none;
                }
                .control {
                    border-top: 0;
                    input {
                        position: static;
                    }
                }
            }
            .action.search {
                margin: 15px 0;
            }
        }
        .more-information {
            display: none;
        }
    }
}

@include max-screen($screen__s) {
    .block-search {
        margin-top: $indent__s;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .block-search {
        position: relative;
        display: inline-block;
        margin: 0 auto;
        width: 300px;
        z-index: 4;
        padding: 15px 0px 0px 0px;

        .field {
          position: relative;
          float: left;
          width: calc(100% - 32px);
        }

        .label {
            @extend .abs-visually-hidden-desktop;
        }

        .control {
            border-top: 0;
            margin: 0;
            padding: 0 0 25px;
        }

        .nested {
            display: block;
            padding-top: 5px;
            position: absolute;
        }

        input {
            @include lib-input-placeholder($form-element-input-placeholder__color);
            margin: 0;
            position: static;
        }
    }

    .search-autocomplete {
        margin-top: 0;
    }
}

//
//  Grid Responsive
//  _____________________________________________


.catalogsearch-result-index {
  .products-grid {
      .product-item {
          flex: 1 25%;
          max-width: 25%;
      }
  }
}

@include max-screen($screen__xl) {
  .catalogsearch-result-index {
      .products-grid {
          .product-item {
              flex: 1 25%;
              max-width: 25%;
          }
      }
  }
}

@include max-screen($screen__l) {
  .catalogsearch-result-index {
      .products-grid {
          .product-item {
              flex: 1 33.3333%;
              max-width: 33.3333%;
          }
      }
  }
}

@include max-screen($screen__m) {
  .catalogsearch-result-index {
      .products-grid {
          .product-item {
              flex: 1 33.3333%;
              max-width: 33.3333%;
          }
      }
  }
}

@include max-screen($screen__s) {
  .catalogsearch-result-index {
      .products-grid {
          .product-item {
              flex: 1 50%;
              max-width: 50%;
          }
      }
  }
}

@include max-screen($screen__xs) {
  .catalogsearch-result-index {
      .products-grid {
          .product-item {
              flex: 1 100%;
              max-width: 100%;
          }
      }
  }
}