a.action.primary,
button {
    @include lib-css(border-radius, $button__border-radius);
}

button {
    &:active {
        @include lib-css(box-shadow, $button__shadow);
    }
}

a.action.primary {
    @include lib-link-as-button();
}

.action.primary {
  position: relative;
  float: left;
  background-color: $color-green1;
  font-family: $secondary__font;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
  text-align: center;
  border: none;
  border-radius: 0px;
  padding: 15px 20px;
  box-sizing: border-box;
  vertical-align: middle;

  span {
    position: relative;
    float: left;
    width: 100%;
    text-align: center;
  }

  &:hover {
    opacity: 0.7;
  }
}

.action.remind {
  font-family: $secondary__font;
  color: $color-red13;
  font-size: 15px;
  font-weight: 600;
}