//
//  Theme variables
//  _____________________________________________

//  Messages
$message-global-note__background           : $color-yellow-light2;
$message-global-note__border-color         : $color-yellow-light3;
$message-global-note__color                : $text__color;

$message-global-note-link__color           : $link__color;
$message-global-note-link__color-hover     : $link__hover__color;
$message-global-note-link__color-active    : $link__active__color;

$message-global-caution__background        : $color-red9;
$message-global-caution__border-color      : none;
$message-global-caution__color             : $color-white;

$message-global-caution-link__color        : $link__color;
$message-global-caution-link__color-hover  : $link__hover__color;
$message-global-caution-link__color-active : $link__active__color;

//  Header
$header__background-color                  : false;
$header-icons-color                        : $color-gray56;
$header-icons-color-hover                  : $color-gray20;

$addto-color                               : $text__color__muted;
$addto-hover-color                         : $primary__color;

$minicart-icons-color                      : $header-icons-color;
$minicart-icons-color-hover                : $header-icons-color-hover;

$button__shadow                            : inset 0 2px 1px rgba(0, 0, 0, 0.12);

$h1__margin-bottom__desktop                : $indent__xl;

//  Footer
$footer__background-color                  : false;


body {
    @include lib-css(background-color, $page__background-color);
    overflow-x: hidden;
}

//
//  Header
//  ---------------------------------------------

.page-header {
    z-index: 99;
    @include lib-css(background-color, $header__background-color);
    border-bottom: 1px solid $border-color__base;
    margin-bottom: $indent__base;
    background-color: $color-white;
}

.header,
.nav-sections {
  z-index: 9;
}

.header {
    &.content {
        @extend .abs-add-clearfix;
        padding-top: $indent__s;
        position: relative;
        float: left;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        z-index: 9;

        .usermenus {
          position: relative;
          float: right;
          list-style: none;
          padding: 15px 0px 0px 0px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          > li {
            position: relative;
            float: left;

            &:after {
              content: "|";
              font-family: $secondary__font;
              margin: 0px 15px;
            }

            &:last-child {
              &:after {
                display: none;
              }
            }

            > a,
            .action.showcart,
            .minicart-wrapper {
              position: relative;
              float: left;
              font-family: $secondary__font;
              font-size: 13px;
              color: $color-black;
              display: flex;
              //flex-wrap: wrap;
              align-items: center;

              &:before {
                content: "";
                position: relative;
                float: left;
                margin-right: 8px;
                background-position: center center;
                background-repeat: no-repeat;
              }

              &:hover {
                text-decoration: none;
              }
            }

            .minicart-wrapper {
              .action {
                .text {
                  position: relative;
                  float: left;
                }
                &.showcart {
                  .counter.qty {
                    position: relative;
                    float: left;
                    background-color: transparent;
                    margin-top: 0px;

                    span,
                    &:after,
                    &:before {
                      position: relative;
                      float: left;
                      color: $color-red13;
                      font-family: $secondary__font;
                      font-size: 12px;
                      font-weight: 900;
                    }

                    &:before {
                      content: "(";
                    }

                    &:after {
                      content: ")";
                    }
                  }

                  &.active {
                    display: flex;
                    &:before {
                      display: none;
                    }
                  }
                }
              }


            }

            &:nth-child(1) {
              a {
                &:before {
                  width: 26px;
                  height: 22px;
                  background-image: url('../images/sprite_shop.png');
                }
              }
            }

            &:nth-child(2) {
              a {
                &:before {
                  width: 20px;
                  height: 19px;
                  background-image: url('../images/sprite_user.png');
                }
              }
            }

            &:nth-child(3) {
              .minicart-wrapper {
                &:before {
                  width: 21px;
                  height: 21px;
                  background-image: url('../images/sprite_cart.png');
                }
              }
            }
          }
        }
    }
}

.logo {
    float: left;
    margin: 0 0 $indent__s $indent__xl;
    width: 175px;
    max-width: 50%;
    position: relative;
    z-index: 5;
    top: 8px;

    img {
        display: block;
    }

    .page-print & {
        float: none;
    }
}

.page-main {
    > .page-title-wrapper {
        .page-title + .action {
            margin-top: $indent__l;
        }
    }
}

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        @include lib-css(background, $color-gray94);
        @include lib-css(padding, $indent__s);
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

.action-skip-wrapper {
    height: 0;
    position: relative;
}

.title-bar,
.product-header {
  position: relative;
  float: left;
  width: 100vw;
  min-height: 40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: $color-green2;
  padding: 0px 40px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  z-index: 4;

  .page-title-wrapper {
    .page-title {
      float: left;
      margin: 0px;
      line-height: normal;
      span {
        float: left;
        font-size: 16px;
        font-weight: 900;
      }
    }

    .product-count {
      float: left;
      font-weight: 600;
      margin: 2px 0px 0px 12px;

      .count {
        font-weight: 900;
      }
    }
  }

  .toolbar-products {
    display: block;
  }

  .separator {
    position: relative;
    float: left;
    width: 10%;
  }

  .right {
    position: relative;
    float: left;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.breadcrumbs,
.page-title-wrapper,
.toolbar {
  position: relative;
  float: left;
  display: block;
  font-family: $secondary__font;
  color: $color-white;
  line-height: normal;
}

.breadcrumbs {
  padding: 0px;
  margin: 0px;
  .item,
  a {
    font-family: $secondary__font;
    font-size: 12px;
    color: $color-white;
    text-transform: capitalize;
  }

  .item {
    a {
      font-family: $secondary__font;
      font-size: 12px;
      color: $color-white;
      text-transform: capitalize;
    }

    &:after {
      content: "/";
      font-size: 10px;
    }

    &:last-child {
      &:after {
        content: "";
        display: none;
      }
    }
  }
}

.banner-top {
  img {
    width: 100%;
    margin-top: 20px;
    vertical-align: bottom;
  }
}

//
//  Global notice
//  ---------------------------------------------

.message.global {
    p {
        margin: 0;
    }

    &.noscript,
    &.cookie {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }

    &.cookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;

        .actions {
            margin-top: $indent__s;
        }
    }

    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}

.advice-wrapper {
  position: relative;
  float: left;
  width: 100%;
  background-color: $color-red13;
  font-family: $secondary__font;
  color: $color-white;
  font-size: 16px;
  font-weight: 500;

  .advice {
    position: relative;
    float: left;
    padding: 6px 0px;
    width: 30%;

    &:first-child {
      text-align: left;
      margin-left: 35px;
    }

    &:nth-child(2) {
      text-align:center;
    }

    &:nth-child(3) {
        float:right;
        text-align: right;
        margin-right: 35px;
    }

    b {
      font-weight: bold;
    }
  }
}

//
//  Footer
//  ---------------------------------------------

.page-footer {
    @include lib-css(background-color, $footer__background-color);
    margin-top: auto;
}

#newsletter-rrss {
  position: relative;
  float: left;
  width: 100%;
  background-image: url('../images/bg.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  .newsletter,
  .rrss {
    position: relative;
    float: left;
    width: 50%;
    padding: 20px 80px;
    box-sizing: border-box;

    h4,
    p {
      position: relative;
      font-family: $secondary__font;
      color: $color-black;
      text-align: center;
    }

    h4 {
      font-size: 15px;
      width: 100%;
      font-weight: 700;
      margin-bottom: 15px;
      text-transform: uppercase;
    }

    p {
      width: 70%;
      font-size: 18px;
      font-weight: 600;
      margin: 0 auto;
      text-align: center;
    }
  }

  .newsletter {
    .container {
      position: relative;
      float: left;
      margin: 40px 0px 0px 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 80%;
      .block {
        width: 100%;
        padding: 0px;
        margin: 0px;
        .field {
          width: 100%;
          padding: 0px;
          margin: 0px;
          .control {
            &:before {
              display: none;
            }
            input {
              position: relative;
              float: left;
              width: 100%;
              height: 50px;
              line-height: 50px;
            }
          }
        }
        .actions {
          width: 24%;
          button {
            position: relative;
            float: left;
            width: 100%;
            height: 50px;
            background-color: $color-green1;
            color: $color-white;
            font-size: 15px;
            font-weight: 700;
            text-align: center;
            border: none;
            border-radius: 0px;

            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }

  .rrss {
    ul {
      position: relative;
      float: left;
      margin: 20px 0px 0px 0px;
      padding: 0px;
      list-style: none;
      left: 50%;
      transform: translateX(-50%);

      li {
        position: relative;
        float: left;
        margin-right: 16px;

        &:last-child {
          margin-right: 0px;
        }

        a {
          position: relative;
          float: left;
          width: 40px;
          height: 40px;
          text-align: center;

          &:hover {
            opacity: 0.7;
          }

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}

#footer-links {
  position: relative;
  float: left;
  width: 100%;
  background-color: $color-black;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 20px 0px;

  .links {
    position: relative;
    float: left;
    font-family: $secondary__font;
    color: $color-white;

    h4,
    ul {
      position: relative;
      float: left;
      clear: both;
    }

    h4 {
      font-size: 17px;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 36px;
    }

    ul {
      list-style: none;
      margin: 0px;
      padding: 0px;

      li {
        position: relative;
        float: left;
        clear: both;
        margin-bottom: 18px;

        a {
          position: relative;
          float: left;
          font-size: 15px;
          font-family: $secondary__font;
          color: $color-white;

          &:hover {
            text-decoration: none;
            color: $color-green1;
          }
        }

        &.phone {
          a {
            &:before {
              content: "";
              position: relative;
              float: left;
              width: 15px;
              height: 15px;
              background-repeat: no-repeat;
              background-position: left top;
              background-size: contain;
              background-image: url('../images/sprite_phone3.png');
              margin-right: 5px;
            }

            &:hover {
              &:before {
                background-image: url('../images/sprite_phone4.png');
              }
            }
          }
        }
      }
    }
  }
}

#logos {
  position: relative;
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 80px;

  img {
    position: relative;
    float: left;
    margin-right: 18px;

    &.secure {
      margin-right: 0px;
      margin-left: 60px;
    }
  }
}

#copyright {
  position: relative;
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 80px 20px 80px;
  font-family: $secondary__font;
  color: $color-black;
  font-size: 14px;
  font-weight: 600;
}

.footer {
    &.content {
        border-top: 1px solid $border-color__base;
        margin-top: 25px;
        padding-bottom: 25px;
        padding-top: 25px;

        ul {
            @extend .abs-reset-list;
        }

        .links {
            > li {
                margin: 0 0 8px;
            }
        }

        .switcher-store {
            margin: 0 0 30px;
        }
    }

    .copyright,
    .bugs {
        display: block;
        margin: 20px 0 0;
    }
}

.page-header,
.page-footer {
    .switcher {
        margin-right: 10px;

        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding            : 0,
                $_dropdown-list-item-padding          : 0,
                $_dropdown-toggle-icon-content        : $icon-down,
                $_dropdown-toggle-active-icon-content : $icon-up,
                $_icon-font-text-hide                 : true,
                $_icon-font-size                      : 22px,
                $_icon-font-line-height               : 22px,
                $_dropdown-list-min-width             : 160px
            );

            ul.dropdown {
                a {
                    display: block;
                    padding: 8px;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        li {
            font-size: $font-size__s;
            margin: 0;
        }

        .label {
            @extend .abs-visually-hidden;
        }

        strong {
            font-weight: $font-weight__regular;
        }
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header,
.page-footer {
    .widget.block {
        @include lib-css(margin, $indent__base 0);
    }
}

.no-display {
    @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
    td {
        padding: 0;
    }
}

//
//  Tablet
//  _____________________________________________

@include max-screen($screen__l) {
  .header.content {
    .usermenus {
      float: left;
      width: 100%;
    }
  }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {

  .advice-wrapper {
    .advice {
      float: none;
      padding: 6px 0px;
      width: 100%;

      &:first-child {
        margin-left: 20px;
      }

      &:nth-child(2) {
        //text-align:left;
      }

      &:nth-child(3) {
          //float:none;
          text-align: end;
          //margin-right: 0px;
      }
    }
  }

  body.no-scroll {
    overflow: hidden;
  }

  .nav-toggle {
    left: unset;
    right: 20px;
  }

  .nav-sections {
    background-color: #e8e8e8;
  }

  .nav-sections-item-title {
    display: none;
  }

  .nav-sections-item-content {
    margin-top: 0px;
  }

  .navigation {
    background-color: #e8e8e8;

    .level0 {
      border: 0px;

      > .level-top {
        text-transform: none;
        font-weight: 700;
      }

      > .level1 {
        font-weight: 400;
      }
    }

    a, a:hover {
      color: #000;
    }

    .level0.active > a:not(.ui-state-active),
    .level0.has-active > a:not(.ui-state-active) {
      color: #000;
    }

    li.level0:last-child {
      border: 0px;
    }

    .submenu:not(:first-child) ul > li a {
      color: #000;
    }
  }

  .breadcrumbs, .page-title-wrapper, .toolbar {
    width: 100%;
  }

  .title-bar .right, .product-header .right {
    width: 100%;
  }

  #newsletter-rrss .newsletter, #newsletter-rrss .rrss {
    width: 100%;
    padding: 10px 20px;
  }

  #footer-links {
    justify-content: unset;

    .links h4, .links ul {
      padding-left: 20px;
    }

    .links ul {
      padding-left: 20px;
    }
  }

  #logos {
    padding: 10px 20px;
  }

  #logos img {
    margin-right: 0px;
  }

  .minicart-wrapper.active .block-minicart {
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
  }

  .header.content {
    padding-top: 0;

    .logo {
      margin: 0;
      width: 120px;
    }
    .usermenus {
      float: right;
      position: inherit;
      margin-top: 25px;
      right: 25px;
      width: 57%;
      justify-content: right;
      /*
      float: left;
      width: 100%;
      padding: 0;
      margin: 0;
      */
      li::after {
        content: none;
      }
      .submenu-stores {
        display: none;
      }
      .minicart-wrapper {
        margin-top: 0px;
      }
    }
  }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

    .page-wrapper {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        min-height: 100%; // Stretch content area for sticky footer

        > .breadcrumbs,
        > .top-container,
        > .widget {
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            width: 100%;
        }

        .ie10 &,
        .ie11 & {
            height: 100%;
        }
    }

    .navigation ul {
        padding: 0 8px;
    }

    .header {
        &.panel {
            > .header.links {
                float: right;
                font-size: 0;
                @include lib-list-inline();
                margin-left: auto;

                > li {
                    font-size: $font-size__base;
                    margin: 0 0 0 15px;

                    &.welcome,
                    a {
                        line-height: 1.4;
                    }

                    &.welcome {
                        a {
                            @include lib-css(padding-left, $indent__xs);
                        }
                    }
                }
            }
        }

        &.content {
            @extend .abs-add-clearfix-desktop;
            padding: 0px $indent__base 0px $indent__base;
        }
    }

    .page-header {
        border: 0;
        margin-bottom: 0;

        .panel.wrapper {
            border-bottom: 1px solid $secondary__color;
        }

        .header.panel {
            @extend .abs-add-clearfix-desktop;
            padding-bottom: $indent__s;
            padding-top: $indent__s;
        }

        .switcher {
            display: inline-block;
        }
    }

    .page-main {
        > .page-title-wrapper {
            .page-title {
                display: inline-block;
            }

            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }

    .logo {
        margin: -8px 0px 14px 0px;

        img {
            max-height: inherit;
        }
    }

    .footer {
        &.content {
            .block {
                float: right;
            }

            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }

            .switcher.store {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;
            }
        }

        .copyright {
            @extend .abs-add-clearfix-desktop;
        }
    }
}
