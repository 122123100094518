//
//  Variables
//  _____________________________________________

$checkout-wrapper__margin              : $indent__base;
$checkout-wrapper__columns             : 8;

$checkout-step-title__border           : $border-width__base solid $color-gray80;
$checkout-step-title__font-size        : 26px;
$checkout-step-title__font-weight      : $font-weight__light;
$checkout-step-title__padding          : $indent__s;

$checkout-step-title-mobile__font-size : 18px;

.checkout-container {
    @extend .abs-add-clearfix;
}

.opc-wrapper {
    @include lib-css(margin, 0 0 $checkout-wrapper__margin);

    .opc {
      position: relative;
      float: left;
      width: 100%;
      padding: 0px;
      list-style: none;

      li {
        @extend .opc;
        margin: 0px 0px 40px 0px;

        &.checkout-shipping-method {
          margin: 0px;
        }
      }
    }

    .step-title {
        font-family: $secondary__font;
        color: $color-red13;
        font-size: 17px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        padding-bottom: 15px;
        border-bottom: 2px dashed $color-red13;
        margin-bottom: 15px;
    }

    .step-content {
        margin: 0 0 $indent__xl;
    }
}

.checkout-index-index {
    background-color: $color-white;

    .authentication-wrapper {
      display: none !important;
    }

    .nav-sections,
    .nav-toggle {
        display: none;
    }

    .logo {
        margin-left: 0;
    }

    .actions-toolbar {
      margin-left: 0px !important;
    }

    /*#store-container-main,
    #light,
    #fade {
      display: none;
    }*/
}

.checkout-onepage-success {
    @extend .abs-add-clearfix;

    .print {
        display: none;
    }

    .page-title-wrapper {
        color: inherit;
    }
}

.checkout-billing-address {
    .billing-title {
        font-size: 16px;
        font-weight: 700;
        color: #000;
    }
    .action-update {
        background: #b62a2e;
        border: 0;
        color: #FFF;
    }

    .action-update:hover,.action-update:focus {
        opacity: 0.7;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .opc-wrapper {
        .step-title {
            @include lib-css(font-size, $checkout-step-title-mobile__font-size);
            border-bottom: 0;
            padding-bottom: 0;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-wrapper {
        position: relative;
        float: left;
        width: 66%;
        order: 1;
        box-sizing: border-box;
        padding: $checkout-wrapper__margin $indent__l $indent__l $indent__l;
        background-color: $color-gray96;
    }

    .checkout-onepage-success {
        .print {
            display: block;
            float: right;
            margin: 22px 0 0;
        }
    }
}
