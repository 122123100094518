//
//  Variables
//  _____________________________________________

$checkout-sidebar__margin: $indent__base;
$checkout-sidebar__margin__xl: 46px;
$checkout-sidebar__columns: 4;

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-sidebar {
      position: relative;
      float: right;
      width: 32%;
      order: 2;
      box-sizing: border-box;
      background-color: $color-gray96;
      padding: $checkout-wrapper__margin $indent__l $indent__l $indent__l;
      margin-bottom: 14px;
    }
}
