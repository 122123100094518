.cms-home {
  .page-title-wrapper {
    display: none;
  }
}

#banners,
#categories,
#discount,
#videos-slider,
#banner,
#map {
  position: relative;
  float: left;
  width: 100%;
}

#banners {
  display: flex;
  flex-wrap: wrap;

  .left,
  .right {
    position: relative;
    float: left;
    height: 100%;
  }

  .left {
    width: 700px;

    .custom-slider {
      width: 100%;
      height: 100%;
      padding: 0px;

      .owl-carousel {
        .owl-stage-outer {
          padding: 0px;
          .owl-item {
            .banner-item {
              margin: 0px !important;
            }
          }
        }

        .owl-controls {
          .owl-dots {
            width: auto;
            right: 10%;
            .owl-dot {
              span {
                background-color: transparent;
                border: 1px solid $color-white;
              }
              &.active {
                span {
                  background-color: $color-white;
                }
              }
            }
          }
        }
      }
    }
  }

  .right {
    width: 560px;

    .small {
      position: relative;
      float: right;
      width: calc(100% - 60px);

      &:last-child {
        margin-top: 30px;
      }

      img {
        position: relative;
        float: left;
        width: 100%;
        height: 220px;
      }
    }
  }
}

#categories {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .item {
    position: relative;
    float: left;
    width: calc(25% - 20px);
    margin-bottom: 30px;
    margin-top: 0px;

    &:after {
      height: 100%; content: "";
      background: rgba(0,0,0,0.8);
      position: absolute; top: 0;
      left: 0px;
      right: 0px;
      opacity: 0;
    }

    &:hover:after,
    &:hover:before { opacity: 1; cursor: pointer;}

    &:before {
      content: "Próximamente";
      position: absolute;
      top: calc(50% - 12px);
      left: calc(50% - 40px);
      color: #ffffff;
      opacity: 0;
      z-index: 1;
    }

    img,
    span {
      position: relative;
      float: left;
      width: 100%;
    }

    img {
      height: auto;
    }

    a {
      img {
        z-index: 2;
      }
    }

    span {
      background-color: $color-white;
      padding: 12px 10px;
      color: $color-black;
      font-size: 18px;
      font-family: $secondary__font;
      font-weight: 900;
      text-align: center;
      box-sizing: border-box;
    }
  }
}

#discount {
  margin: 40px 0px 60px 0px;
  background-color: $color-green2;
  display: flex;
  flex-wrap: wrap;

  .left,
  .right {
    position: relative;
    float: left;
    width: 50%;
  }

  .left {
    padding: 40px 80px;
    box-sizing: border-box;

    .title,
    .text,
    .form {
      position: relative;
      float: left;
      width: 80%;
      font-family: $secondary__font;
      color: $color-white;
    }

    .title {
      font-size: 40px;
      font-weight: 800;
      line-height: 40px;
      margin-bottom: 15px;
    }

    .text {
      font-size: 24px;
      font-weight: 600;
    }

    .form {
      margin-top: 15px;

      input,
      button {
        position: relative;
        float: left;
        display: block;
        height: 50px;
        line-height: 50px;
      }

      input {
        width: 80%;
      }
      
      button {
        width: 20%;
        background-color: $color-green1;
        color: $color-white;
        font-size: 15px;
        font-weight: 700;
        text-align: center;
        padding: 0;
        border: 0;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  .right {
    overflow: hidden;
    
    img {
      position: relative;
      float: left;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

#videos-slider,
#map,
#company,
#filosofy {
  padding: 40px 0px;

  h3,
  h4 {
    position: relative;
    float: left;
    clear: both;
    width: 100%;
    text-align: center;
  }

  h3 {
    font-family: $secondary__font;
    color: $color-red13;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 50px;
    margin-top: 0px;
  }

  h4 {
    font-family: $other__font;
    color: $color-black;
    font-size: 25px;
    font-weight: 400;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 55px;

    &:after {
      content: "";
      position: relative;
      float: left;
      clear: both;
      width: 5%;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 20px;
      height: 1px;
      border-bottom: 3px dashed $color-red13;
    }
  }

  .subtitle {
    font-family: $other__font;
    color: $color-black;
    font-size: 25px;
    font-weight: 400;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 55px;

    &:after {
      content: "";
      position: relative;
      float: left;
      clear: both;
      width: 5%;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 20px;
      height: 1px;
      border-bottom: 3px dashed $color-red13;
    }
  }
}

#videos-slider {
  .videos {
    position: relative;
    float: left;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .video {
      position: relative;
      float: left;
      width: calc(33.33% - 20px);

      iframe,
      span {
        position: relative;
        float: left;
        width: 100%;
      }

      span {
        background-color: $color-white;
        padding: 12px 10px;
        color: $color-black;
        font-size: 18px;
        font-family: $secondary__font;
        font-weight: 700;
        text-align: center;
        box-sizing: border-box;
      }
    }
  }
}

#banner {
  margin: 40px 0px;
  background-color: $color-green3;
  display: flex;
  flex-wrap: wrap;

  .left,
  .right {
    position: relative;
    float: left;
    width: 50%;
  }

  .left {
    padding: 40px 80px;
    box-sizing: border-box;

    .title,
    .subtitle,
    .text,
    a {
      position: relative;
      float: left;
      width: 100%;
      font-family: $secondary__font;
      color: $color-white;
    }

    .title {
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    .subtitle {
      font-size: 40px;
      font-weight: 800;
      line-height: 40px;
      margin-bottom: 8px;
    }

    .text {
      font-size: 26px;
      font-style: italic;
    }

    a {
      width: auto;
      padding: 12px 24px;
      color: $color-white;
      font-size: 18px;
      font-weight: 700;
      background-color: $color-green1;
      margin-top: 30px;

      &:hover {
        text-decoration: none;
        opacity: 0.7;
      }
    }
  }

  .right {
    overflow: hidden;
    
    img {
      position: relative;
      float: left;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

#company {
  .left,
  .right {
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 0px 0px;
  }

  .left,
  .right {
    width: 48%;
    margin-bottom: 40px;
  }
}

#filosofy {
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  background-color: $color-white;
  padding: 40px 2%;
  box-sizing: border-box;
  font-family: $secondary__font;
  color: $color-black;
  font-weight: 600;
  position: relative;
  clear: both;
  margin-bottom: 30px;

  .filosofy-wrapper {
    max-width: 1300px;
    margin: 0 auto;

    h6,p {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  h6 {
    font-family: $secondary__font;
    color: $color-red13;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 20px 0px 10px 0px;
  }
}

#map {
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  background-color: $color-white;
  padding: 40px 2%;
  box-sizing: border-box;
  font-family: $secondary__font;
  color: $color-black;
  font-weight: 600;

  .left,
  .center,
  .right {
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 0px 26px;

    h6 {
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      margin: 0px 0px 40px 0px;
    }
  }

  .left,
  .right {
    width: 48%;
  }

  .left {
    label,
    input,
    select,
    a {
      position: relative;
      float: left;
      width: 100%;
    }

    label {
      font-size: 14px;
      margin-bottom: 10px;

      &.last {
        margin-top: 30px;
      }
    }

    input,
    select {
      height: 50px;
      line-height: 50px;
      padding: 0px 10px;
      box-sizing: border-box;
    }

    a {
      width: auto;
      left: 50%;
      transform: translateX(-50%);
      padding: 12px 24px;
      background-color: $color-green1;
      color: $color-white;
      font-size: 15px;
      font-weight: 700;
      text-align: center;
      border: none;
      border-radius: 0px;
      margin-top: 30px;

      &:hover {
        opacity: 0.7;
        text-decoration: none;
      }
    }
  }

  .left {
    padding: 0 15%;
    
    .address,
    .phone,
    .hours {
      font-size: 15px;
      margin-bottom: 32px;
      display: flex;

      &:before {
        content: "";
        width: 16px;
        background-repeat: no-repeat;
        background-position: left top;
        background-size: contain;
        margin-right: 12px;
      }

      br {
        margin-bottom: 10px;
      }
    }

    .address {
      &:before {
        height: 23px;
        background-image: url('../images/sprite_marker.png');
      }
    }

    .phone {
      &:before {
        height: 24px;
        background-image: url('../images/sprite_phone2.png');
      }
    }

    .hours {
      &:before {
        height: 17px;
        background-image: url('../images/sprite_clock.png');
      }
    }
  }

  .right {
    img {
      position: relative;
      float: left;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}


//
//  Tablet
//  _____________________________________________
@media screen and (max-width: 1299px) {
  #banners {
    .left, .right {
      width:100%;
    }
    .right {
      .small {
        margin-top: 30px;
        width:50%;

        img {
          height: auto;
        }
      }
    }
  }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
  #banners {
    .left, .right {
      .small {
        width:100%;
      }
    }
  }

  #categories .item {
    float:none;
    width: 100%;
  }

  #discount .left {
    padding: 10px 20px;
  }

  #discount .left, #discount .right {
    width: 100%;
  }

  #banner .left {
    padding: 10px 20px;
  }

  #banner .left, #banner .right {
    width: 100%;
  }

  #discount .left .title, #discount .left .text, #discount .left .form {
    width: 100%;
  }

  #videos-slider .videos .video {
    width: 100%;
  }

  #map .center {
    width: 100%;
  }

  #map .left, #map .right {
    width: 100%;
  }

}
