//
//  Shopping cart
//  ---------------------------------------------

.checkout-cart-index {
    .page-wrapper {
        background-color: #FFF;
    }
}

.cart-container {
    clear: both;
}

//  Summary block
.cart-summary {
    @extend .abs-add-box-sizing;
    @extend .abs-adjustment-incl-excl-tax;
    @include lib-css(background, $sidebar__background-color);
    margin-bottom: $indent__m;
    padding: 1px 15px $indent__m;

    > .title {
        display: block;
        @include lib-heading(h3);
    }

    .block {
        @extend .abs-discount-block;
        margin-bottom: 0;

        .item-options {
            margin-left: 0;
        }

        .fieldset {
            margin: 15px 0 $indent__m $indent__xs;

            .field {
                margin: 0 0 $indent__s;

                &.note {
                    font-size: $font-size__s;
                }
            }

            .methods {
                .field {
                    > .label {
                        display: inline;
                    }
                }
            }
        }

        .fieldset.estimate {
            > .legend,
            > .legend + br {
                @extend .abs-no-display;
            }
        }
    }
    .actions-toolbar {
        > .primary {
            button {
                @extend .abs-revert-secondary-color;
            }
        }
    }
}

//  Totals block
.cart-totals {
    @extend .abs-sidebar-totals;
    border-top: 1px solid $border-color__base;
    padding-top: $indent__s;

    .table-wrapper {
        margin-bottom: 0;
        overflow: inherit;
    }
}

//  Products table
.cart.table-wrapper {
    .items {
        thead + .item {
            border-top: $border-width__base solid rgba(182,42,46,0.1);
        }

        > .item {
            border-bottom: $border-width__base solid rgba(182,42,46,0.1);
            position: relative;
        }
    }

    .col {
        padding-top: $indent__base;


        &.sku {
            display:none;
        }

        &.qty {
            .input-text {
                @extend .abs-input-qty;
                margin-top: -5px;
            }

            .label {
                @extend .abs-visually-hidden;
            }
        }
        &.name {
            width: 25%;
        }
    }

    .item {
        &-actions td {
            padding-bottom: $indent__s;
            text-align: center;
            white-space: normal;
        }

        .col {
            &.item {
                display: block;
                min-height: 75px;
                padding: $indent__m 0 $indent__s 75px;
                position: relative;
            }
            &.name {
                width: 25%;
            }

            &.price {
                .buyfive {
                    position:absolute;
                    bottom: 20px;
                }
                .buyten {
                    position:absolute;
                    bottom: 40px;
                }
            }
        }
    }

    .actions-toolbar {
        @extend .abs-add-clearfix;

        > .action {
            @extend button;
            @include lib-link-as-button();
            margin-bottom: $indent__s;
            margin-right: $indent__s;

            &:last-child {
                margin-right: 0;
            }

            &.action-delete,&.action-towishlist {
                background: $color-green2;
                font-size: 0px;
                height: 30px;
                width: 30px;
                margin-bottom: 0px;
                padding: 0;
            }

            &.action-delete::before {
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: 28px;
                line-height: 28px;
                color: #FFF;
                content: "\e604";
                font-family: icons-blank-theme;
                vertical-align: middle;
                display: inline-block;
                font-weight: 400;
                overflow: hidden;
                text-align: center;
                left: 0;
                top: 0;
            }

            &.action-towishlist::before {
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: 28px;
                line-height: 28px;
                color: #FFF;
                content: "\e601";
                font-family: icons-blank-theme;
                vertical-align: middle;
                display: inline-block;
                font-weight: 400;
                overflow: hidden;
                text-align: center;
                left: 0;
                top: 0;
            }
        }
    }

    .action {
        &.help.map {
            @extend .abs-action-button-as-link;
            font-weight: $font-weight__regular;
        }
    }

    .product {
        &-item-photo {
            display: block;
            left: 0;
            max-width: 60px;
            padding: 0;
            position: absolute;
            top: 15px;
            width: 100%;
        }

        &-item-details {
            white-space: normal;
        }

        &-item-name {
            display: inline-block;
            font-weight: $font-weight__regular;
            margin-top: -6px;
        }
    }

    .gift-registry-name-label {
        &:after {
            content: ':';
        }
    }

    //  Product options
    .item-options {
        @extend .abs-product-options-list;
        @extend .abs-add-clearfix;
        font-size: $font-size__s;
        margin-bottom: $indent__s;
    }

    .product-item-name {
        padding: 0;
        overflow: visible;
    }

    .product-item-name + .item-options {
        margin-top: $indent__s;
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }

    .action.configure {
        display: inline-block;
        margin: 0 0 $indent__base;
    }
}

.cart-container {
    .form-cart {
        float: none;
        position:relative;
        width: 100%;
    }

    .checkout-methods-items {
        @extend .abs-reset-list;
        margin-top: $indent__base;
        text-align: center;

        .action.primary {
            @extend .abs-button-l;
            width: 100%;
        }

        .item {
            & + .item {
                margin-top: $indent__base;
            }
        }
    }
}

.cart {
    &.area-top {
        margin-top: 30px;
    }

    &.area-bottom,
    &.area-top {
        .grand-total {
            float:left;

            .label {
                font-weight: 700;
            }

            .label:after {
                content: ': ';
            }

            .value {
                font-size: 22px;
                font-weight: 700;
                color: #000;
            }
        }

        .action.primary.checkout {
            float:right;
        }


        .action.primary.checkout,
        .action.primary.checkout:hover,
        .action.primary.checkout:focus,
        .action.primary.checkout:active {
            padding: 10px 100px;
            background: $color-red13;
            border: 1px solid $color-red13;
            border-radius: 0;
            color: #fff;
        }
        .action.primary.checkout:hover {
            opacity: 0.7;
        }.print{
             padding: 10px 15px;
             border-radius: 0;
             color: #95cbbb;
             width: 100px;
             float: right;
         }

    }
}


//  Products pager
.cart-products-toolbar {
    .toolbar-amount {
        margin: $indent__m 0 15px;
        padding: 0;
        text-align: center;
    }

    .pages {
        margin: 0 0 $indent__m;
        text-align: center;

        .items {
            > .item {
                border-bottom: 0;
            }
        }
    }
}

.cart-products-toolbar-top {
    border-bottom: $border-width__base solid $border-color__base;
}

#block-discount {
    .title {
        display:none;
    }

    .fieldset.coupon {
        margin: 0 auto;
        text-align: center;

        > div {
            display:inline-flex;
            float: none;
            width: inherit;

            &.field {
                .control {
                    display: inline-flex;

                    input {
                        padding: 20px 200px 20px 20px;
                    }
                }
                .label,
                .control {
                    width: inherit;
                    float: none;
                }
            }
            &.actions-toolbar {
                margin: 0;
                .primary {
                    float: none;
                    background: $color-green1;
                    border: 0px;
                    border-radius: 0px;
                    padding: 6px;
                }
            }
        }
    }
}

.summary-total {
    .price {
        font-weight: 700;
    }
    .price::after {
        content: ' ';
        display: block;
    }
}

//
//  Cross sell
//  ---------------------------------------------

.block.crosssell {
    margin-top: 70px;
}

.cart-tax-info,
.cart .cart-tax-info {
    + .cart-tax-total {
        display: block;
    }
}

.cart.table-wrapper,
.order-items.table-wrapper {

    .cart.item {
        td {
            vertical-align: top;

            .product-item-name a {
                color: $color-red13;
                font-size: 22px;
            }

            .roll {
                font-size: 20px;
            }

            .control.qty {

                .qty-control {
                    height: 25px;
                    width: 25px;
                    background: $color-green2;
                    display: inline-block;
                    vertical-align: top;
                    text-align: center;
                    padding-top: 5px;
                    color:#FFF;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.7;
                    }
                }

                .input-text.qty {
                    margin-top: 0px;
                    margin-left: -4px;
                    margin-right: -3px;
                    $tocart-input-size: $button__line-height__l + 30px;
                    height: 30px; /*$tocart-input-size;*/
                    text-align: center;
                    width: 37px; /*$tocart-input-size;*/
                    border: 1px solid $color-black;
                    background-color: transparent;
                    font-family: $secondary__font;
                    font-size: 14px;
                }
            }
        }
        .col.price {
            .unit-price {
                font-size: 13px;
                .price-including-tax, .price-excluding-tax {
                    display:inline-block;
                    font-size: 10px;

                    .price {
                        font-weight: normal;
                    }
                }
            }
            .original-price {
                font-size: 13px;
            }
            .total-price {
                font-weight: 700;
                .price-including-tax, .price-excluding-tax {
                    display:inline-block;

                    .price {
                        font-weight: 700;
                    }
                }
            }
            .unidad{
                text-align: center;
                text-transform: uppercase;
                padding-bottom: 17px;
            }
        }
    }

    .col.subtotal,
    .col.msrp {
        text-align: right;
    }
}

//
//  Mobile
//  _____________________________________________
@include max-screen($screen__xs) {
    .columns {
        display:block;
    }

    .cart {
        &.area-bottom,
        &.area-top {
            width: 100%;
            .grand-total{
                margin: 20px;
            }
            .action.primary.checkout {
                //float:left;
                //width:100%;
                margin-bottom: 20px;
            }
        }
        .table-wrapper {
            .col{
                width: 100% !important;
            }
        }
    }
    .cart-container{
        table { border: 0; }

        table thead { display: none; }

        table tr {
            margin-bottom: 10px;
            display: block;
            border-bottom: 2px solid #ddd;
        }

        table td {
            display: block;
            text-align: right;
            font-size: 13px;
            border-bottom: 1px dotted #ccc;
            width: 100% !important;
        }

        table td:last-child { border-bottom: 0; }

        table td:before {
            content: attr(data-label);
            float: left;
            text-transform: uppercase;
            font-weight: bold;
        }
    }
}


//
//  Tabled
//  _____________________________________________

@include max-screen($screen__m) {
    .columns {
        display:block;
    }

    .cart {

        &.area-bottom,
        &.area-top {
            .action.primary.checkout {
                float:left;
                width:100%;
                margin-bottom: 20px;
            }
        }

        &.table-wrapper {
            overflow: inherit;

            thead {
                .col {
                    &:not(.item) {
                        display: none;
                    }
                }
            }

            .item {

                .col {
                    &.item {
                        display:none;
                    }

                    &.name {
                        width: 100%;
                        .roll {
                            display: none;
                        }
                    }

                    &.price {
                        .original-price,
                        .unit-price {
                            display: none;
                        }
                        .ahorro{
                            display:none;
                        }
                        .unidad{
                            display: none;
                        }
                    }

                    &.qty,
                    &.price,
                    &.subtotal,
                    &.msrp {
                        box-sizing: border-box;
                        display: flex;
                        float: none;
                        text-align: center;
                        white-space: nowrap;
                        width: 33%;
                    }

                    &.msrp {
                        white-space: normal;
                    }
                }
            }
        }
    }

    .cart-container {

        .form-cart {
            @extend .abs-shopping-cart-items-mobile;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include max-screen(1280px) {
    .cart {
        &.table-wrapper {
            .item {
                .col.name {
                        width:22%;
                }
            }
        }
    }
}

@include min-screen(1024px) {
    .cart {
        &.table-wrapper {
            .item {
                .col {
                        display:table-cell;
                }
            }
        }
    }
}


@include min-screen($screen__m) {
    .cart-container {
        @extend .abs-add-clearfix-desktop;
        .form-cart {
            @extend .abs-shopping-cart-items-desktop;
        }

        .widget {
            float: left;
        }
    }

    .cart-summary {
        float: right;
        position: relative;
        width: 23%;

        .actions-toolbar {
            .column.main & {
                @extend .abs-reset-left-margin-desktop;
                > .secondary {
                    float: none;
                }
            }
        }

        .block {
            .fieldset {
                .field {
                    @include lib-form-field-type-revert($_type: block);
                    margin: 0 0 $indent__s;
                }
            }
        }
    }

    .cart {
        &.table-wrapper {
            .items { // Google Chrome version 44.0.2403.107 m fix
                min-width: 100%;
                width: auto;
            }

            .item {
                .col {
                    &.item {
                        padding: 27px 8px $indent__s;
                    }
                }

                &-actions {
                    td {
                        text-align: right;
                    }
                }
            }

            .product {
                &-item-photo {
                    display: table-cell;
                    max-width: 100%;
                    position: static;
                    vertical-align: top;
                    width: 1%;
                }

                &-item-details {
                    display: table-cell;
                    vertical-align: top;
                    white-space: normal;
                    width: 99%;
                }
                &-sku {
                   font-size: 20px;
                }
                &-item-name {
                    font-size: 22px;
                    text-align: left;

                }


            }

            .item-actions {
                .actions-toolbar {
                    @extend .abs-reset-left-margin-desktop;
                    text-align: left;
                }
            }
        }

        .action {
            margin-bottom: $indent__s;
        }
    }

    //  Products pager
    .cart-products-toolbar {
        .toolbar-amount {
            line-height: 30px;
            margin: 0;
        }

        .pages {
            float: right;
            margin: 0 0 1px;

            .item {
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .cart.table-wrapper {
        .cart-products-toolbar {
            + .cart {
                thead {
                    tr {
                        th.col {
                            padding-bottom: 7px;
                            padding-top: 8px;
                        }
                    }
                }
            }
        }

        .cart {
            + .cart-products-toolbar {
                margin-top: $indent__m;
            }
        }
    }

}
