$widgets-indent__bottom: $indent__base;

.page-wrapper > .block-static-block.widget {
    margin: 0;
    padding: 0;
    max-width: 100%;
}

.block-static-block,
.block-cms-link {
    &.widget {
        @include lib-css(margin-bottom, $widgets-indent__bottom);
        .links & {
            margin-bottom: 0;
        }
    }
}

.block-cms-link-inline {
    &.widget {
        margin: 0;
    }
}
