.products-slider {
  position: relative;
  float: left;
  width: 100%;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  background-color: $color-white;
  padding: 40px 2%;
  box-sizing: border-box;

  h3,
  h4 {
    position: relative;
    float: left;
    clear: both;
    width: 100%;
    text-align: center;
  }

  h3 {
    font-family: $secondary__font;
    color: $color-red13;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 50px;
    margin-top: 0px;
  }

  h4 {
    font-family: $other__font;
    color: $color-black;
    font-size: 25px;
    font-weight: 400;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 55px;

    &:after {
      content: "";
      position: relative;
      float: left;
      clear: both;
      width: 5%;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 20px;
      height: 1px;
      border-bottom: 3px dashed $color-red13;
    }
  }

  .subtitle {
    font-family: $other__font;
    color: $color-black;
    font-size: 25px;
    font-weight: 400;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 55px;

    &:after {
      content: "";
      position: relative;
      float: left;
      clear: both;
      width: 5%;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 20px;
      height: 1px;
      border-bottom: 3px dashed $color-red13;
    }
  }

  .owl-slider,
  .custom-slider {
    position: relative;
    float: left;
    width: 100%;
    padding: 0px;

    .title {
      display: none;
    }

    #owl-carousel{
      width: 94%;
      max-width: $layout__max-width;
      padding-left: $layout-indent__width;
      padding-right: $layout-indent__width;
      box-sizing: border-box;
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: 0px;

      .owl-stage-outer {
        z-index: 1;
        padding: 0px;
        height: 400px;

        .owl-item {
          box-sizing: border-box;

          .product-item {
            position: relative;
            float: left;
            width: 86% !important;
            left: 7%;
            box-sizing: border-box;
            margin: 0px !important;
            padding: 0px;

            .product-item-info {
              position: relative;
              float: left;
              width: inherit;
              left: 50%;
              transform: translateX(-50%);

              .product-item-photo {
                position: relative;
                float: left;
                width: 100%;

                .product-image-container {
                  position: relative;
                  float: left;
                  width: 100%;

                  .product-image-wrapper {
                    position: relative;
                    float: left;
                    width: 100%;
                    height: auto;
                    padding-bottom: 0% !important;

                    .product-image-photo {
                      position: relative;
                      float: left;
                      width: 100%;
                      height: auto;
                      margin: 0px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .owl-controls {
        position: absolute;
        width: 106%;
        height: 100%;
        left: -3%;
        top: 0px;
        z-index: 0;

        .owl-prev,
        .owl-next {
          width: 44px;
          height: 44px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
          margin: 0px;
          top: 50% !important;
          transform: translateY(-50%);

          &:after,
          &:before {
            display: none;
          }

          &:hover {
            opacity: 0.7;
          }
        }

        .owl-prev {
          left: 0px;
          background-image: url('../images/slider_arrow_left.png');
        }

        .owl-next {
          right: 0px;
          background-image: url('../images/slider_arrow_right.png');
        }

        .owl-dots {
          display: none !important;
        }
      }
    }
  }

  .product-item-details {
    display: none;
  }
}

.product-info-main {
  .product-item-details {
    display: block;
    margin-bottom: 10px;

    .product-item-name {
      display:none;
    }

    .product-item-desc {
      height: 100%;

      a:hover {
        opacity: 0.7;
        text-decoration: none;
      }

      span {
        display: block;
        font-family: nunito,sans-serif;
        color: #000;
        font-size: 14px;
        font-weight: 700;
      }
    }

    .price-box {
      display:none;
    }
  }
}


.cms-home {
  .custom-slider {
    .product-item-details {
      display: block;
      margin-bottom: 10px;

      .product-item-name {
        height: 100%;
        margin-top: 100px;
        position: absolute;
        top: 200px;
      }
      .price-box {
        display:none;
      }
    }
  }
}

.product-add-form .products-slider {
  width: 90% !important;
  padding: 0px 2%;
  margin: 40px 0;

    @media only screen and (max-width: 768px){
      width: 85vw !important;
    }

    @media only screen and (max-width: 480px){
      width: 79vw !important
    }
}

