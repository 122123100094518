/* iCheck plugin Square skin, red
----------------------------------- */
.icheckbox_square-red,
.iradio_square-red {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 20px;
    height: 18px;
    background: url('../images/sprite_icheck.png') no-repeat;
    border: none;
    cursor: pointer;
}

.icheckbox_square-red {
    background-position: -19px 0;
}
    .icheckbox_square-red.hover {
        background-position: -39px 0;
    }
    .icheckbox_square-red.checked {
        background-position: -39px 0;
    }
    .icheckbox_square-red.disabled {
        background-position: -59px 0;
        cursor: default;
    }
    .icheckbox_square-red.checked.disabled {
        background-position: -79px 0;
    }

.iradio_square-red {
    background-position: -117px 0;
}
    .iradio_square-red.hover {
        background-position: -137px 0;
    }
    .iradio_square-red.checked {
        background-position: -137px 0;
    }
    .iradio_square-red.disabled {
        background-position: -156px 0;
        cursor: default;
    }
    .iradio_square-red.checked.disabled {
        background-position: -174px 0;
    }

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (min-resolution: 1.25dppx) {
    .icheckbox_square-red,
    .iradio_square-red {
        background-image: url('../images/sprite_icheck@2x.png');
        -webkit-background-size: 240px 24px;
        background-size: 240px 24px;
    }
}