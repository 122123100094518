//
//  Variables
//  _____________________________________________

$checkout-sidebar-shipping-information__padding                : $indent__l;
$checkout-sidebar-shipping-information__line-height            : $checkout-billing-address-details__line-height;

$checkout-sidebar-shipping-information-edit-icon__color        : $minicart-icons-color;
$checkout-sidebar-shipping-information-edit-icon__content      : $icon-settings;
$checkout-sidebar-shipping-information-edit-icon__font-size    : 28px;
$checkout-sidebar-shipping-information-edit-icon__line-height  : 28px;
$checkout-sidebar-shipping-information-edit-icon__top          : 2px;
$checkout-sidebar-shipping-information-edit-icon__hover__color : $primary__color;

//
//  Shipping Information
//  ---------------------------------------------

%full-width {
  position: relative;
  float: left;
  width: 100%;
}

.opc-block-shipping-information {
    @extend %full-width;
    margin-top: 50px;

    .shipping-information {
      @extend %full-width;

      a {
        color: $color-red13;
      }
    }

    .shipping-information-title {
        @extend %full-width;
        font-family: $secondary__font;
        color: $color-red13;
        font-size: 17px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        padding-bottom: 15px;
        border-bottom: 2px dashed $color-red13;
        margin-bottom: 15px;

        .action-edit {
            @extend .abs-action-button-as-link;
            @include lib-css(top, $checkout-sidebar-shipping-information-edit-icon__top);
            @include lib-icon-font(
                $checkout-sidebar-shipping-information-edit-icon__content,
                $_icon-font-size         : $checkout-sidebar-shipping-information-edit-icon__font-size,
                $_icon-font-line-height  : $checkout-sidebar-shipping-information-edit-icon__line-height,
                $_icon-font-text-hide    : true,
                $_icon-font-color        : $checkout-sidebar-shipping-information-edit-icon__color,
                $_icon-font-color-hover  : $checkout-sidebar-shipping-information-edit-icon__hover__color,
                $_icon-font-color-active : $checkout-sidebar-shipping-information-edit-icon__color
            );
            margin: 0;
            position: absolute;
            right: 0;
        }
    }

    .shipping-information-content {
        @include lib-css(line-height, $checkout-sidebar-shipping-information__line-height);
        .actions-toolbar {
            margin-left: 0;
        }
    }

    .ship-to,
    .ship-via {
        margin: 0 0 40px 0px;
    }
}
