.mpblog-post-index #maincontent {
    max-width: 100%;
    padding: 0;
}

#mpblog-list-container {
    .pager {
        .toolbar-amount {
            display:none;
        }
        .limiter {
            display:none;
        }
        .pages {
            margin: 0 auto;
            text-align: center;
            strong.page {
                font-size: 16px;
                color: $color-red13;
            }
            ul {
                width: 100%;
                position: relative;
                background: #FFF;
                padding-top: 30px;
                padding-bottom: 30px;
                .item {
                    font-size: 16px;
                }
                a.action {
                    display: block;
                    width: 44px;
                    height: 44px;
                    border: 0;
                    position:absolute;
                    top: 25px;

                    &.next {
                        right: 20px;
                        background-image: url("../images/slider_arrow_right.png");
                    }

                    &.next::before {
                        content: '';
                    }

                    &.previous {
                        left: 20px;
                        background-image: url("../images/slider_arrow_left.png");
                    }
                    
                    &.previous::before {
                        content: '';
                    }

                    span {
                        display: none;
                    }
                }
            }
        }
    }
}

.post-list-item {
    padding: 70px 20px;
    background-color: #e8e8e8;
    .post-item-wraper {
        width: 1300px;
        margin: 0 auto;

        .post-image {
            min-width: 50%;
            display: table-cell;

            img {
                width: 100%;
            }
          }
          .post-info-wraper {
            display: table-cell;
            vertical-align: top;
            padding-left: 20px;

            h2 {
                font-size: 16px;
                font-weight: 700;
                color: #000;
                a {
                    color: #000;
                }
            }

            .mp-read-more {
                display: inline-block;
                background: $color-green1;
                margin-top: 30px;
                padding: 15px 35px;
                color: #FFF;
            }
          }
      
    }
}

.post-list-item:nth-child(odd) {
    background-color: #FFF;
}

body.mpblog-post-view {
    background-color: #FFF;

    #mpblog-list-container {
        margin-top: 30px;

        .post-list-content {
            .post-view-image {
                min-width: 50%;
                display: table-cell;
            }
            .post-post_content {
                display: table-cell;
                vertical-align: top;
                padding-left: 20px;
                padding-bottom: 50px;

                h2 {
                    margin-top: 0px;
                    margin-bottom: 30px;
                    font-size: 16px;
                    font-weight: 700;
                    color: #000;
                }
            }
        }
    }
}