//
//  Variables
//  _____________________________________________

$checkout-field-validation__border-color: $form-element-validation__border-error;

.field {
  position: relative;
  float: left;
  width: 100%;

    .control {
        width: 100%;

        &._with-tooltip {
            @extend .abs-field-tooltip;
        }
    }

    &.choice {
        .field-tooltip {
            display: inline-block;
            margin-left: $indent__s;
            position: relative;
            top: -3px;
        }

        .field-tooltip-action {
            @include lib-css(line-height, $checkout-tooltip-icon__font-size);
        }
    }

    &._error {
        .control {
            input,
            select,
            textarea {
                @include lib-css(border-color, $checkout-field-validation__border-color);
            }
        }
    }
}

.opc-wrapper {
    form {
      position: relative;
      float: left;
      width: 100%;
    }

    .fieldset {
      position: relative;
      float: left;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;

        > fieldset {
          .field {
            width: 100%;
          }

          .actions-toolbar {
            position: relative;
            float: left;
            width: 100%;
            font-family: $secondary__font;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            div.primary,
            div.secondary {
              position: relative;
              float: left;
              margin: 0px 20px;

              .action {
                &.primary {
                  margin: 20px 0px 0px 0px;
                }
              }
            }
          }
        }

        > .field {
            width: 48%;

            > .label {
                width: 100%;
            }

            > input,
            > select {
              width: 100%;
            }

            &:not(.choice) {
              > .control {
                width: 100%;
              }
            }
        }

        &#customer-email-fieldset {
          > .field {
            width: 100%;
          }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    //  ToDo UI: remove with global blank theme .field.required update
    .opc-wrapper {
        .fieldset {
            > .field {
                &.required,
                &._required {
                    > label {
                        padding-right: 14px;

                        &:after {
                            margin-left: $indent__s;
                            position: absolute;
                            top: 9px;
                        }
                    }
                }
            }
        }
    }
}
