//
//  Product Lists
//  _____________________________________________

.category.wrapper .page-title-wrapper.category {
    position: relative;
    float: left;
    width: 75%;
    padding-left: 30px;
    box-sizing: border-box;
     height: auto;

    h1 {
      position: relative;
      float: left;
      width: 100%;
      font-family: $secondary__font;
      color: $color-red13;
      font-size: 28px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      padding-bottom: 15px;
      border-bottom: 2px dashed $color-red13;
      margin-bottom: 30px;
    }

    .product-count {
        display: none;
    }
}

.category-header .block.filter {
    margin-bottom: 0px;
    div {
        display: inline-block;
    }

    .clean-all {
        display: none;
    }

    .filtered > a {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-align: center;
        align-items: center;
        background-color: #FFF;
    }

    a:hover {
        text-decoration: none;
    }

    .filtered > a > .filter-name,
    .filter-options-title {
        margin:0px;
        text-transform: uppercase;
        padding: 10px 25px;
        color: #FFF;
        font-weight: 700;

    }
    .filtered > a > .filter-name {
        background-color: rgba(150,203,186,0.7);
    }

    .filter-options-title {
        background-color: $color-green3;

        a {
            color: #FFF;
        }
    }

    .dropdown-toggle {
        cursor: pointer;

        &:after {
            content: "";
            position: relative;
            float: right;
            width: 14px;
            height: 8px;
            margin-left: 10px;
            margin-top: 5px;
            background-image: url("../images/sprite_arrow_select.png");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
        }
    }
    .dropdown-menu {
        display: block;
        position: absolute;
        background-color: $color-green3;
        font-family: $secondary__font;
        color: $color-white;
        font-weight: 700;

        .items {
            padding: 10px 15px;
        }

        a {
          font-family: $secondary__font;
          color: $color-white;
          font-weight: 600;
        }
    }
}

.category.wrapper {
    .sidebar-additional {
         height: 660px;
        overflow-x: hidden;
        overflow-y: scroll;
      /*position: fixed;*/
    }
    .products-grid {
         height: auto;
        // overflow-x: hidden;
        // overflow-y: scroll;
    }
}

.products {
    /*margin: 0px 0px $indent__l 0px;*/
    margin: 0px 0px 0px 0px;
}

.product {
    &-items {
        @extend .abs-reset-list;
    }

    &-item {
        @extend .abs-add-box-sizing;
        vertical-align: top;
        background-color: $color-white;
        padding: 0px 1%;
        background-clip: content-box;
        margin-bottom: 20px !important;

        .products-grid & {
            display: inline-block;
        }

        &-name {
            @extend .abs-product-link;
            width: 100%;
            height: 55px;
            text-align: center;
            display: block;
            margin: $indent__xs 0;
            padding: 0px 12px;
            box-sizing: border-box;
            overflow: hidden;

            &.pieza {
                height: 90px;
            }

            a {
              font-family: $secondary__font;
              color: $color-black;
              font-size: 14px;
              font-weight: 700 !important;

              &:hover {
                text-decoration: none;
              }
            }
        }

        &-info {
            position: relative;
            float: left;
            max-width: 100%;
            width: 100%;
        }

        &-label {
          position: absolute;
          top: 0px;
          left: 0px;
          font-family: $secondary__font;
          color: $color-white;
          font-size: 14px;
          font-weight: 700;
          text-transform: lowercase;
          text-align: center;
          z-index: 2;

          &-new {
            position: relative;
            float: left;
            background-color: $color-green3;
            padding: 5px;
          }

          &-percent {
            position: relative;
            float: left;
            background-color: $color-red13;
            padding: 5px;
          }

          &-text {
            float: right;
            color: $color-red13;
            text-transform: uppercase;
            margin: 4px 40px;
          }
        }

        &-actions {
          position: relative;
          float: left;
          width: 100%;
          margin-bottom: 20px;

          .actions-primary,
          .actions-secondary {
            position: relative;
            float: left;
            width: 100%;
          }

          .actions-primary {
            .tocart {
              position: relative;
              float: left;
              width: 80%;
              left: 10%;
              text-align: center;
              background-color: $color-red13;
              border: none;
              border-radius: 0px;
              margin-bottom: 6px;

              span {
                font-family: $secondary__font;
                font-size: 14px;
                color: $color-white;
              }

              &:hover {
                opacity: 0.7;
              }
            }
          }

          .actions-secondary {
            .towishlist {
              position: relative;
              float: left;
              width: 80%;
              left: 10%;
              text-align: center;
              background-color: $color-green1;
              border: none;
              border-radius: 0px;
              margin-bottom: 6px;
              padding: 7px 15px;
              box-sizing: border-box;
              vertical-align: middle;

              span {
                font-family: $secondary__font;
                font-size: 14px;
                color: $color-white;
                width: auto !important;
                height: auto !important;
                margin: 0px !important;
                clip: initial !important;
                position: initial !important;
              }

              &:before {
                display: none !important;
              }

              &:hover {
                opacity: 0.7;
              }
            }

            .tocompare {
              display: none;
            }
          }
        }

        &-description {
            margin: $indent__m 0;
        }

        .product-reviews-summary {
            .rating-summary {
                margin: 0 4px 0 0;
            }

            .reviews-actions {
                font-size: $font-size__s;
                margin-top: 5px;
                text-transform: lowercase;
            }
        }

        .prices_table {
            margin: 10px;
            width: calc(100% - 20px);
            th {
                font-weight: 400;
            }
            th, td {
                margin: 0px;
                padding: 0px;
                border: 1px #000 solid;
                text-align: center;
            }

            .old-price {
                margin-left: 0px;
                border-bottom: 1px #000 solid;
            }
        }

        .price-box {
            position: relative;
            display: flex;
            flex-direction: column-reverse;
            width: 100%;

            .price {
                white-space: nowrap;
                font-family: $secondary__font;
                font-size: 18px;
                font-weight: 800;
                color: #FF2800;
            }

            .price-label {
                display: none;
            }
        }

        .price-label{
            font-size: 14px;
            margin: 5px 25px;
        }

        .price-info{
            font-size: 11px;
            margin: 0 20px;
            text-align: right;

            .internet_unidad{
                white-space: nowrap;
                font-family: "Nunito", sans-serif;
                font-size: 18px;
                font-weight: 800;
                color: #FF2800;
                width: 100%;
            }
            .tienda_unidad{
                white-space: nowrap;
                font-family: "Nunito", sans-serif;
                font-size: 14px;
                font-weight: 800;
                color: #99cd84;
                width: 100%;
            }
        }

        .available-meters {
            white-space: nowrap;
            font-family: "Nunito", sans-serif;
            font-size: 16px;
            font-weight: 800;
            color: #FF2800;
            width: 100%;
            text-align: center;
        }

        .pieces-price {
            text-align: center;
        }

        .m_x_roll {
            float: left;
            position: relative;
            width: 100%;
            margin: 5px 25px;
        }

        .special-price,
        .minimal-price {
            .price-wrapper {
                display: inline-block;
            }

            .price-including-tax + .price-excluding-tax {
                display: block;
            }
        }

        .special-price {
            display: block;
        }

        .minimal-price {
            .price-container {
                display: block;
            }
        }

        .old-price {
          margin-left: 8px;
          .price {
            font-size: 14px;
            color: $color-green3;
          }
        }

        .special-price,
        .old-price,
        .minimal-price {
          position: relative;
        }


        .minimal-price-link {
            margin-top: 5px;

            .price-label {
                color: $link__color;
                font-size: 14px;
            }

            .price {
                font-weight: $font-weight__regular;
            }
        }

        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
        }

        .minimal-price-link {
            display:none;
        }

        .price-from,
        .price-to {
            margin: 0;
        }
    }
}

.price-container {
    .price {
        font-size: 14px;
    }

    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .price-including-tax + .price-excluding-tax,
    .weee,
    .price-including-tax + .price-excluding-tax .price,
    .weee .price,
    .weee + .price-excluding-tax:before,
    .weee + .price-excluding-tax .price {
        font-size: 11px;
    }

    .weee {
        &:before {
            content: '('attr(data-label) ': ';
        }

        &:after {
            content: ')';
        }

        + .price-excluding-tax {
            &:before {
                content: attr(data-label) ': ';
            }
        }
    }
}

.products-list {
    .product {
        &-item {
            display: table;
            width: 100%;

            &-info {
                display: table-row;
            }

            &-photo {
                display: table-cell;
                padding: 0 $indent__l $indent__l 0;
                vertical-align: top;
                width: 1%;
            }

            &-details {
                display: table-cell;
                vertical-align: top;
            }
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }
}

//
//  Mobile
//  _____________________________________________

@include min-screen($screen__s) {
    .product {
        &-item {
            .products-grid & {
                margin-bottom: $indent__l;
            }

            &-actions {
                display: block;

                .products-grid & {
                    margin: $indent__s 0;
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

//
//  Grid Responsive
//  _____________________________________________

.page-products {
    .products-grid {
        .product-items {
            position: relative;
            float: left;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
        }
        .product-item {
            flex: 1 25%;
            max-width: 25%;
        }
    }
}

@include max-screen($screen__xl) {
    .page-products {
        .products-grid {
            .product-item {
                flex: 1 25%;
                max-width: 25%;
            }
        }
    }
}

@include max-screen($screen__l) {
    .page-products {
        .products-grid {
            .product-item {
                flex: 1 33.3333%;
                max-width: 33.3333%;
            }
        }
    }
}

@include max-screen($screen__m) {
    .category.wrapper .page-title-wrapper.category {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
    }

    .category-header .block.filter .dropdown-menu {
        position:relative;
    }

    .page-products {
        .products-grid {
            .product-item {
                flex: 1 33.3333%;
                max-width: 33.3333%;
            }
        }
    }
}

@include max-screen($screen__s) {
    .page-products {
        .products-grid {
            .product-item {
                flex: 1 50%;
                max-width: 50%;
            }
        }
    }
}

@include max-screen($screen__xs) {
    .page-products {
        .products-grid {
            .product-item {
                flex: 1 100%;
                max-width: 100%;
            }
        }
    }
}
