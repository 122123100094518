//
//  Variables
//  _____________________________________________

$checkout-summary__background-color : $color-white-smoke;
$checkout-summary__padding          : 22px $indent__l;

$checkout-summary-title__margin     : $indent__s;
$checkout-summary-mark-value__color : $color-gray60;

$checkout-summary-items__max-height : 370px;
$checkout-summary-items__padding    : 15px;

//
//  Order Summary
//  ---------------------------------------------

.opc-block-summary {
    @extend .abs-add-box-sizing;

    > .title {
        position: relative;
        float: left;
        width: 100%;
        font-family: $secondary__font;
        color: $color-red13;
        font-size: 17px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        padding-bottom: 15px;
        border-bottom: 2px dashed $color-red13;
        margin-top: 94px;
    }

    //  Totals table
    .table-totals {
        @extend .abs-sidebar-totals;
    }

    .mark {
        .value {
            @include lib-css(color, $checkout-summary-mark-value__color);
            display: block;
        }
    }

    .grand.incl {
        & + .grand.excl {
            .mark,
            .amount {
                border-top: 0;
                @include lib-font-size(14);
                padding-top: 0;

                strong {
                    font-weight: $font-weight__regular;
                }
            }
        }
    }

    .not-calculated {
        font-style: italic;
    }

    //
    //  Items list
    //  ---------------------------------------------

    //  Block title
    .items-in-cart {
        > .title {
            border-bottom: $border-width__base solid $border-color__base;
            @include lib-css(padding, $indent__s $indent__xl $indent__s 0);
            cursor: pointer;
            @include lib-icon-font(
                $icon-down,
                $_icon-font-size        : 30px,
                $_icon-font-line-height : 12px,
                $_icon-font-text-hide   : true,
                $_icon-font-margin      : 3px 0 0,
                $_icon-font-position    : after,
                $_icon-font-display     : block
            );
            margin-bottom: 0;
            position: relative;

            &:after {
                position: absolute;
                right: 0;
                top: $indent__s;
            }

            strong {
                @include lib-font-size(18);
                font-weight: $font-weight__light;
                margin: 0;
            }
        }

        &.active {
            > .title {
                @include lib-icon-font-symbol(
                    $_icon-font-content  : $icon-up,
                    $_icon-font-position : after
                );
            }
        }

        .product {
            position: relative;
        }
    }

    //  Cart items
    .items-in-cart,
    .minicart-items {
      position: relative;
      float: left;
      width: 100%;
    }

    .minicart-items-wrapper {
      position: relative;
      float: left;
      width: 100%;
      padding: 0px;
      margin: 0px;
      border: 0px;
      font-family: $secondary__font;
      font-size: 16px;
      color: $color-black;
    }

    .column.main & {
      .product-item {
        position: relative;
        float: left;
        width: 100%;
        margin: 0;
        padding-left: 0;
        background-color: transparent;

        .product-image-container {
          display: none;
        }

        .product {
          position: relative;
          float: left;
          width: 100%;
          margin-top: 20px;

          .product-item-details {
            width: 100%;
            margin: 0px;
          }
        }

        .product-item-inner {
            width: 100%;
        }

        .product-item-name-block {
            text-align: left;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .product-item-name,
            .details-unitary,
            .details-qty {
              position: relative;
              float: left;
            }

            .product-item-name {
              color: $color-red13;
              font-weight: 700;
              margin: 0px;
              width: 40%;
              height: unset;
            }

            .details-unitary,
            .details-qty {
              margin: 0px;
              padding: 0px;
              font-size: 13px;
            }

            .details-unitary {
              width: 40%;
              text-align: center;
            }

            .details-qty {
              width: 18%;
              font-weight: 700;
              text-align: right;

              .label {
                display: none;
              }
            }
        }

        .subtotal {
            display: table-cell;
            text-align: right;
        }

        .price {
            @include lib-font-size(16);
            font-weight: $font-weight__regular;
        }

        .price-including-tax {
            & + .price-excluding-tax {
                margin: 0;

                .price {
                    @include lib-font-size(10);
                }
            }
        }
      }
    }

    .table-totals {
        font-family: $secondary__font;
        font-size: 14px;
        color: $color-black;

        td {
            font-weight: 800;
        }

        .totals-tax {
            display:none;
        }

        .grand.totals.incl {
            td {
                font-size: 18px;
            }
        }

        .grand.totals.excl {
            display: none;
        }
    }

    .actions-toolbar {
        position: relative;
        z-index: 1;

        .secondary {
            border-top: $border-width__base solid $border-color__base;
            display: block;
            float: none;
            margin: -1px 0 0;
            padding: 15px 0 0;
            text-align: left;

            .action {
                margin: 0;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-summary-wrapper {
        .modal-header {
            .action-close {
                display: none;
            }
        }
    }
}
