$product-grid-items-per-row-layout-default          : 2;

$product-grid-items-per-row-layout-1-screen-s       : 3;
$product-grid-items-per-row-layout-1-screen-m       : 4;
$product-grid-items-per-row-layout-1-screen-l       : 5;

$product-grid-items-per-row-layout-2-left-screen-s  : 3;
$product-grid-items-per-row-layout-2-left-screen-m  : 4;
$product-grid-items-per-row-layout-2-left-screen-l  : '';

$product-grid-items-per-row-layout-2-right-screen-s : 3;
$product-grid-items-per-row-layout-2-right-screen-m : 4;
$product-grid-items-per-row-layout-2-right-screen-l : '';

$product-grid-items-per-row-layout-3-screen-s       : 3;
$product-grid-items-per-row-layout-3-screen-m       : '';
$product-grid-items-per-row-layout-3-screen-l       : '';

$product-grid-items-padding                         : 0 $indent__base $indent__base;
$product-grid-items-margin                          : 0 0 $indent__s;

$product-name-text-decoration                       : none;
$product-name-text-decoration-hover                 : $link__hover__text-decoration;

$toolbar-mode-icon-font-size                        : 26px;
$product-h1-margin-bottom-desktop                   : $indent__base;


@import 'module/listings';
@import 'module/toolbar';
@import 'module/gallery';
@import 'view';

//
//  Category view
//  ---------------------------------------------

.old-price,
.old.price {
    text-decoration: line-through;
}

.prices-tier {
    .price-container {
        .price-including-tax {
            + .price-excluding-tax {
                &:before {
                    content: '(' attr(data-label) ': ';
                }

                &:last-child:after {
                    content: ')';
                }
            }
        }

        .weee[data-label] {
            display: inline;

            .price {
                @include lib-font-size(11);
            }

            &:before {
                content: ' +' attr(data-label) ': ';
            }
        }
    }
}

.actual-price {
    font-weight: $font-weight__bold;
}

.product.name a {
    @extend .abs-product-link;
}

.category-image {
    .image {
        display: block;
        height: auto;
        max-width: 100%;
    }
}

.category-description {
    margin-bottom: $indent__base;
}

.category-image {
  margin-top: 10px;
  img {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.category.wrapper {
  position: relative;
  float: left;
  width: 100%;
  margin-top: 16px;

  .products-grid {
    position: relative;
    float: right;
    width: 75%;
    padding-left: 30px;
    box-sizing: border-box;
  }

  .ias-noneleft {
    display: none !important;
  }
}

//
//  Product images general container
//  ---------------------------------------------

.product-image-container {
    display: inline-block;
    max-width: 100%;
    width: 100% !important;
}

.product-image-wrapper {
    display: block;
    height: 0;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.product-image-photo {
    bottom: 0;
    display: block;
    height: auto;
    left: 0;
    margin: auto;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
}

//
//  Sidebar product view
//  ---------------------------------------------

.sidebar {

  position: relative;
  float: left;
  width: 25%;
  padding: 0px;
  margin: 0px;

  .filter {
    .filter-title {
      display: none;
    }

    .filter-content {
      position: relative;
      float: left;
      width: 100%;
      box-sizing: border-box;
      padding: 20px;
      background-color: $color-gray95;

      ul {
        position: relative;
        float: left;
        width: 100%;
        list-style: none;
        margin: 0px;
        padding: 0px;

        .level1 {
          position: relative;
          float: left;
          width: 100%;

          a {
            font-weight: 700;
            font-size: 16px;
            color: $color-black;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
          }

          .subcategories {
            margin-left: 15px;

            .level2, .level3 {
                position: relative;
                float: left;
                width: 100%;
                margin: 10px 0px 0px 0px;

                > a, > span {
                    font-weight: 400;
                    font-size: 14px;

                    &:after {
                        display: none;
                    }
                }

                .subcategories {
                    margin-left: 15px;

                    li {
                        font-size: 13px;
                        font-weight: 700;
                    }
                }


                &:last-child {
                    margin-bottom: 10px;
                }

                &.active {
                    > a {
                        color: $color-red13;
                    }
                }
            }
          }

          &.active {
            > a {
              color: $color-red13;
            }
          }

          &.head-list {
              margin-bottom: 50px;
          }

          &.empty {
            a {
              &:after {
                display: none;
              }
            }
          }
        }

        a, span{
          position: relative;
          float: left;
          width: 100%;
          font-family: $secondary__font;

          &:hover {
            color: $color-red13;
            text-decoration: none;
          }
        }
      }
    }
  }

    .product-items {
        .product-item {
            margin-bottom: $indent__base;
            position: relative;
        }

        .product-item-info {
            position: relative;
            width: auto;
            height: 585px;

            .product-item-photo {
                left: 0;
                position: absolute;
                top: 0;
            }
        }

        .product-item-name {
            margin-top: 0;
        }

        .product-item-details {
            margin: 0 0 0 85px;
        }

        .product-item-actions {
            display: block;
            margin-top: $indent__s;
        }

        .price-box {
            display: block;
            margin: 7px 0;
        }

        .text {
            margin-right: 8px;
        }

        .counter {
            @include lib-css(color, $primary__color__lighter);
            @include lib-font-size(12);
            white-space: nowrap;
        }

        .minilist {
            .price {
                display: inline;
                padding: 0;
            }

            .weee:before {
                display: inline-block;
            }
        }
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .subtitle {
        @extend .abs-no-display;
    }

    //
    //  Product images only
    //  ---------------------------------------------

    .product-items-images {
        @extend .abs-add-clearfix;
        margin-left: -$indent__xs;

        .product-item {
            @extend .abs-add-box-sizing;
            float: left;
            padding-left: $indent__xs;
        }
    }

    //
    //  Product names only
    //  ---------------------------------------------

    .product-items-names {
        .product-item {
            margin-bottom: $indent__s;
        }

        .product-item-name {
            margin: 0;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .columns .sidebar-additional {
        display: none;
    }

    .category-header .block.filter div {
        display: block;
    }

    .category.wrapper .products-grid {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
    }

    .catalog-product-view {
        .column.main {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-prefix-flex-direction(column);
        }

        .product.media {
            @include lib-vendor-prefix-order(-1);
        }
    }

    .product-info-main .box-tocart {
        .actions {
            .action.tocart {
                @extend .abs-button-responsive-smaller;
            }
        }
    }

    .block.related {
        .action.select {
            display: block;
            margin: $indent__xs 0;
        }
    }

    .compare,
    .product-addto-links .action.tocompare,
    .product-item-actions .actions-secondary > .action.tocompare,
    [class*='block-compare'] {
        display: none;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: left;
                    left: auto;
                    margin: 0 $indent__s $indent__s 0;
                    position: relative;
                    top: auto;
                }
            }

            .product-item-details {
                margin: 0;
            }

            .product-item-actions {
                clear: left;
            }
        }
    }

    .product-add-form {
        @extend .abs-revert-field-type-desktop;
    }
}

//
//  Desktop large
//  _____________________________________________

@include min-screen($screen__xl) {
    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: none;
                    left: 0;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }

            .product-item-details {
                margin-left: 85px;
            }
        }
    }
}

//
//  Category page layout
//  ---------------------------------------------

@include min-screen($screen__m) {
    .product-info-main {
        float: right;
    }

    .product.media {
        float: left;
        margin-bottom: $indent__m;
      margin-top: 100px;
    }

    .page-layout-1column {
        .product-info-main {
            width: 67%;
        }
        .product.media {
            width: 30%;
        }
    }

    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .page-layout-3columns {
        .product-info-main {
            width: 48%;
        }

        .product.media {
            width: 50%;
        }
    }
}

//
//  Compare Products Page
//  ---------------------------------------------

body.catalog-product-compare-index {
    .action.print {
        float: right;
        margin: 15px 0;
    }
}

.table-wrapper.comparison {
    clear: both;
    max-width: 100%;
    overflow-x: auto;
}

.table-comparison {
    table-layout: fixed;

    .cell.label.remove,
    .cell.label.product {
        span {
            @extend .abs-visually-hidden;
        }
    }

    .cell.label,
    td:last-child {
        border-right: $table__border-width $table__border-style $table__border-color;
    }

    .cell {
        padding: 15px;
        width: 140px;

        .attribute.value {
            overflow: hidden;
            width: 100%;
        }

        &.product.info,
        &.product.label {
            border-bottom: $table__border-width $table__border-style $table__border-color;
        }

        &.label {
            .attribute.label {
                display: block;
                width: 100%;
                word-wrap: break-word;
            }
        }

        &.attribute {
            @include lib-font-size(13);
            img {
                height: auto;
                max-width: 100%;
            }
        }
    }

    .product-item-photo {
        display: block;
        margin: 0 auto 15px;
    }

    .product-image-photo {
        margin-left: 0;
    }

    .product-item-actions,
    .price-box,
    .product.rating,
    .product-item-name {
        display: block;
        margin: 15px 0;
    }

    .product-addto-links {
        margin-top: 15px;

        .action.split,
        .action.toggle {
            @include lib-button-s();
        }

        .action.toggle {
            padding: 0;
        }
    }

    .cell.remove {
        padding-bottom: 0;
        padding-top: 0;
        text-align: right;

        .action.delete {
            @extend .abs-remove-button-for-blocks;
        }
    }

    .product-item-actions {
        > .actions-primary {
            + .actions-secondary {
                margin-top: $indent__s;
            }
        }
    }

    .action {
        &.tocart {
            white-space: nowrap;
        }
    }
}

.comparison.headings {
    @include lib-css(background, $page__background-color);
    left: 0;
    position: absolute;
    top: 0;
    width: auto;
    z-index: 2;
}

.block-compare {
    .block-title {
        @extend .abs-block-title;
    }

    .product-item .product-item-name {
        margin-left: 22px;
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            left: -6px;
            position: absolute;
            top: 0;
        }

        &.compare {
            @extend .abs-revert-secondary-color;
        }
    }

    .counter {
        @extend .abs-block-items-counter;
    }

    .actions-toolbar {
        margin: 17px 0 0;
    }
}
