.select-wrapper {
  position: relative;
  float: left;
  
  select {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    left: 0px !important;
    top: 0px !important;
    margin: 0px;
    z-index: 1;
  }

  .customSelect { 
    position: relative;
    float: left;
    margin: 0px 10px 0px 0px;
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;

    .customSelectInner {
      position: relative;
      float: left;
      width: auto !important;
    }

    &:after {
      content: "";
      position: relative;
      float: left;
      width: 14px;
      height: 8px;
      margin-left: 10px;
      background-image: url('../images/sprite_arrow_select.png');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }

    .customSelectHover {
    }
    
    .customSelectOpen {
    }
    
    .customSelectFocus {
    }
    
    .customSelectDisabledOption {
    }
    
    .customSelectDisabled {
    }
  }
}