
.cms-no-route {
  .column.main {
    p {
      position: relative;
      float: left;
      width: 100%;
      margin-top: 20px;
      font-family: $secondary__font;
      color: $color-black;
      font-weight: 800;
      font-size: 30px;
      text-align: center;
    }

    ul {
      position: relative;
      float: left;
      width: 100%;
      list-style: none;
      padding: 0px;
      font-family: $secondary__font;
      color: $color-black;
      font-weight: 400;
      font-size: 15px;
      text-align: center;

      a {
        font-family: $secondary__font;
        color: $color-red13;
        font-weight: 400;
        font-size: 15px;
      }
    }
  }

  #categories {
    position: relative;
    float: left;
    width: 100%;
  }

  #categories {
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
      position: relative;
      float: left;
      width: calc(25% - 20px);
      margin-bottom: 30px;

      img,
      span {
        position: relative;
        float: left;
        width: 100%;
      }

      img {
        height: auto;
      }

      span {
        background-color: $color-white;
        padding: 12px 10px;
        color: $color-black;
        font-size: 18px;
        font-family: $secondary__font;
        font-weight: 900;
        text-align: center;
        box-sizing: border-box;
      }
    }
  }
}